import { Routes, Route } from "react-router-dom";
import { AuthLayout } from "./AuthLayout";
import { Login } from "./components/Login";
import { ForgotPassword } from "./components/ForgotPassword";
import { NewPassword } from "./components/NewPassword";
import { VerifyEmail } from "./components/VerifyEmail";

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="verify-code" element={<VerifyEmail />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
};

export default AuthPage;
