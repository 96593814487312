import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../module/auth/hook/useAuth";

interface PrivateRouteProps {
  allowedRoles: string[];
  children?: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  allowedRoles,
  children,
}) => {
  const { isAuthenticated, userRole } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/dashboard/assignments/table-assignments" replace />;
  }

  return <>{children || <Outlet />}</>;
};

export default PrivateRoute;
