import { Routes, Route, Outlet } from "react-router-dom";
import { DashboardLayout } from "./DashboardLayout";
import { CreateUser } from "./component/users/CreateUser";
import TableUser from "./component/users/TableUser";
import Settings from "./component/settings/Settings";
import TableAssignments from "./component/assignments/TableAssignments";
import TableBoard from "./component/board/TableBoard";
import { CreateAssignments } from "./component/assignments/CreateAssignments";
import PrivateRoute from "../../routing/PrivateRouter";
import PreviewAssignments from "./component/assignments/PreviewAssignments";
import SelectTypeAssignment from "./component/assignments/components/SelectTypeAssignment";
import { CreateDepartment } from "./component/settings/components/CreateDepartment";
import InvoiceFile from "./component/invoices/components/InvoiceFile";
import InvocesPDFViewer from "./component/invoices/components/InvocesPDFViewer";
import InvoicesTable from "./component/invoices/InvoicesTable";
import TableBoardInvoice from "./component/invoices/board/TableBoardInvoice";
import { useRoles } from "../../hooks/useRoles";

export const DashboardPage = () => {
  const { getRoleIdsByName } = useRoles();

  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="assignments" element={<Outlet />}>
          <Route
            path="table-assignments"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <TableAssignments />
              </PrivateRoute>
            }
          />
          <Route
            path="table-board"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                  "User",
                ])}
              >
                <TableBoard />
              </PrivateRoute>
            }
          />
          <Route
            path="table-assignments/upload-file"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <PreviewAssignments />
              </PrivateRoute>
            }
          />
          <Route
            path="table-assignments/create-assignment"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                  "User",
                ])}
              >
                <CreateAssignments />
              </PrivateRoute>
            }
          />
          <Route
            path="table-assignments/preview-assignment"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <PreviewAssignments />
              </PrivateRoute>
            }
          />
          <Route
            path="table-assignments/select-type-assignment"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <SelectTypeAssignment />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="invoices" element={<Outlet />}>
          <Route
            path="invoices-board"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                  "User",
                ])}
              >
                <TableBoardInvoice />
              </PrivateRoute>
            }
          />
          <Route
            path="table-invoices"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <InvoicesTable />
              </PrivateRoute>
            }
          />
          <Route
            path="table-invoices/invoices-file"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <InvoiceFile />
              </PrivateRoute>
            }
          />
          <Route
            path="table-invoices/invoices-pdf"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <InvocesPDFViewer />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="settings" element={<Outlet />}>
          <Route
            path=""
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName(["AppCreator", "SuperAdmin"])}
              >
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="create-department"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName(["AppCreator", "SuperAdmin"])}
              >
                <CreateDepartment />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="users" element={<Outlet />}>
          <Route
            path="table-users"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <TableUser />
              </PrivateRoute>
            }
          />
          <Route
            path="create-user"
            element={
              <PrivateRoute
                allowedRoles={getRoleIdsByName([
                  "AppCreator",
                  "Admin",
                  "SuperAdmin",
                ])}
              >
                <CreateUser />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};
