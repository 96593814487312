import React, { useCallback, useEffect, useState } from "react";
import { NavbarDefault } from "../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import NoResultFound from "../../../../../_emailApp/layout/components/NoResultFound";
import { IoPencilSharp, IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPI } from "../../../../api/fetchAPI";
import { setRefreshDepartments } from "../../../../../features/departments/departmentSlice";
import UserDepartementSettings from "./components/UserDepartementSettings";
import { Department } from "../../../../../features/departments/types";
import { RootState } from "../../../../store";
import LoadingSpinner from "../../../../../_emailApp/layout/components/LoadingSpinner";
import { setError } from "../../../../../features/errors/errorSlice";
import { BASE_API_URL } from "../../../../constanst/constantURL";

interface HeaderColumnProps {
  label: string;
  className?: string;
}

const HeaderColumn: React.FC<HeaderColumnProps> = ({ label, className }) => (
  <th
    className={`px-2 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-sm tracking-none whitespace-nowrap opacity-70 ${className}`}
  >
    {label}
  </th>
);

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [noResultFound, setNoResultFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [departments, setDepartments] = useState<Department[]>([]);

  const refreshDepartments = useSelector(
    (state: RootState) => state.departments.refreshDepartments
  );

  const fetchDeparment = useCallback(async () => {
    setNoResultFound(false);
    setLoading(true);
    try {
      const response = await fetchAPI<any>({
        url: `${BASE_API_URL}/department`,
        method: "GET",
      });

      if (response.data) {
        setDepartments(response.data);
      } else {
        setNoResultFound(true);
      }
    } catch (error) {
      setNoResultFound(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDelete = async (departmentId: string) => {
    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/department/${departmentId}`,
        method: "DELETE",
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "El departamento fue eliminado con éxito",
          })
        );
      }

      if (response.status === 409) {
        dispatch(
          setError({
            errorCode: 409,
            errorMessage:
              "No se puede eliminar el departamento debido a un conflicto.",
          })
        );
      }

      dispatch(setRefreshDepartments());
    } catch (error) {
      return;
    }
  };

  const handleEditDepartment = (department?: Department) => {
    navigate("/dashboard/settings/create-department", {
      state: { department },
    });
  };

  useEffect(() => {
    fetchDeparment();
  }, [refreshDepartments, fetchDeparment]);

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault title="Ajustes" />
      <UserDepartementSettings onAddDepartment={() => handleEditDepartment()} />
      <div className="overflow-x-auto mx-5L lg:mx-20">
        {loading ? (
          <div className="flex justify-center py-10">
            <LoadingSpinner />
          </div>
        ) : noResultFound ? (
          <NoResultFound />
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="align-bottom">
              <tr>
                <HeaderColumn label="Nombre departamento" />
                <HeaderColumn label="Descripción departamento" />
                <HeaderColumn label="Acciones" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {departments.map((department: Department) => (
                <tr key={department.departmentId}>
                  <td className="p-2 align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                    <p className="mb-0 font-medium text-left leading-tight text-sm text-label-primary">
                      {department.departmentName}
                    </p>
                  </td>
                  <td className="p-2 align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                    <p className="mb-0 font-medium text-left leading-tight text-sm text-label-primary">
                      {department.departmentDescription}
                    </p>
                  </td>
                  <td className="p-2 text-left align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                    <div className="flex justify-evenly">
                      <IoPencilSharp
                        className="mr-5 cursor-pointer"
                        onClick={() => handleEditDepartment(department)}
                      />
                      <IoTrashOutline
                        className="cursor-pointer"
                        onClick={() =>
                          handleDelete(department.departmentId as string)
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Settings;
