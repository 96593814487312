interface IconCheckmarkOutlineSVGProps {
  className?: string;
  width?: number;
  isDone?: boolean;
}

const IconCheckmarkOutlineSVG: React.FC<IconCheckmarkOutlineSVGProps> = ({
  className,
  width,
  isDone,
}) => {
  const colorCheck = isDone ? 'rgba(36, 167, 73, 1)' : 'rgba(217, 217, 217, 1)';

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M500 0C555.228 0 600 44.7715 600 100V500C600 555.228 555.228 600 500 600H100C44.7715 600 0 555.228 0 500V100C0 44.7715 44.7715 0 100 0H500ZM448.989 165.656L233.333 381.311L151.011 298.989C141.248 289.226 125.419 289.226 115.656 298.989C105.893 308.752 105.893 324.581 115.656 334.344L215.656 434.344C225.419 444.107 241.248 444.107 251.011 434.344L484.344 201.011C494.107 191.248 494.107 175.419 484.344 165.656C474.581 155.893 458.752 155.893 448.989 165.656Z"
        fill={colorCheck}
      />
    </svg>
  );
};

export default IconCheckmarkOutlineSVG;
