import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "../../../../../../_emailApp/layout/components/TextField";
import Typography from "../../../../../../_emailApp/layout/components/Typography";
import { fetchAPI } from "../../../../../api/fetchAPI";
import { User } from "../../users/TableUser";
import FilteredSelect from "../../../../../../_emailApp/layout/components/FilteredSelect";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../../../_emailApp/layout/components/Button";
import { FaSpinner } from "react-icons/fa";
import { setRefreshInvoice } from "../../../../../../features/invoices/invoiceSlice";
import { setError } from "../../../../../../features/errors/errorSlice";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { BASE_API_URL } from "../../../../../constanst/constantURL";
import AlertCard from "../../../../../../_emailApp/layout/components/AlertCard";

const createUserSchema = Yup.object({
  project: Yup.string().required("El proyecto es requerido"),
  assignedTo: Yup.string().required("El nombre es requerido"),
  invoiceNumber: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(1000, "Máximo 50 caracteres")
    .required("El número de factura es requerido"),
  invoiceIssueDate: Yup.string().required("La fecha de emisión es requerida"),
  invoiceDueDate: Yup.string().required("La fecha de vencimiento es requerida"),
  vendorName: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(1000, "Máximo 100 caracteres")
    .required("El nombre del proveedor es requerido"),
  vendorNit: Yup.string().max(20, "Máximo 20 caracteres"),
  vendorAddress: Yup.string().max(1000, "Máximo 100 caracteres"),
  vendorPhone: Yup.string().max(20, "El teléfono debe contener solo números"),
  taxAmount: Yup.string().required("Monto inválido"),
  totalAmount: Yup.string().required("El monto total es requerido"),
});

interface InvoiceTableProps {
  fileUpload: any;
  tableData: {
    invoiceNumber?: string;
    invoiceIssueDate?: string;
    invoiceDueDate?: string;
    vendorName?: string;
    vendorAddress?: string;
    vendorPhone?: string;
    vendorNit?: string;
    tax?: string;
    total?: string;
    tableDetails?: {
      item: string | number;
      quantity: string | number;
      price: string | number;
      total: string | number;
    }[];
  };
}

const CreateInvoice: React.FC<InvoiceTableProps> = ({
  tableData,
  fileUpload,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [allUsers, setAllUsers] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const invoiceToEdit = location.state?.invoice || {};

  const initialValues = {
    project: invoiceToEdit?.project || "",
    assignedTo: invoiceToEdit?.assignedTo?.userId || "",
    invoiceNumber:
      invoiceToEdit?.invoiceNumber || tableData?.invoiceNumber || "",
    invoiceIssueDate: invoiceToEdit?.invoiceIssueDate
      ? moment(invoiceToEdit.invoiceIssueDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )
      : tableData?.invoiceIssueDate
        ? moment(tableData.invoiceIssueDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
    invoiceDueDate: invoiceToEdit?.invoiceDueDate
      ? moment(invoiceToEdit.invoiceDueDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      : tableData?.invoiceDueDate
        ? moment(tableData.invoiceDueDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
    vendorName: invoiceToEdit?.vendorName || tableData?.vendorName || "",
    vendorNit: invoiceToEdit?.vendorNit || tableData?.vendorNit || "",
    vendorAddress:
      invoiceToEdit?.vendorAddress || tableData?.vendorAddress || "",
    vendorPhone: invoiceToEdit?.vendorPhone || tableData?.vendorPhone || "",
    taxAmount: formatCurrency(
      Number(invoiceToEdit?.taxAmount || tableData?.tax || 0)
    ),
    totalAmount: formatCurrency(
      Number(invoiceToEdit?.totalAmount || tableData?.total || 0)
    ),
    invoiceUrl: "",
    details:
      invoiceToEdit?.details?.map((detail: any) => ({
        item: detail.item || "",
        quantity: detail.quantity || "",
        price: formatCurrency(Number(detail.price || 0)),
        total: formatCurrency(Number(detail.total || 0)),
      })) ||
      tableData?.tableDetails?.map((detail) => ({
        item: detail.item || "",
        quantity: detail.quantity || "",
        price: formatCurrency(Number(detail.price || 0)),
        total: formatCurrency(Number(detail.total || 0)),
      })) ||
      [],
  };

  const allUsersOptions = allUsers?.map((user: User) => ({
    value: user.userId ?? "",
    label: `${user.name} ${user.lastName}`,
  }));

  const cleanCurrency = (value: string) => {
    return Number(value.replace(/[^0-9]/g, ""));
  };

  const getAllUser = async () => {
    setLoadingUsers(true);
    try {
      const resp = await fetchAPI({
        url: `${BASE_API_URL}/user`,
        method: "GET",
      });
      if (
        Array.isArray(resp.data) &&
        resp.data.every((item) => typeof item.userId !== "undefined")
      ) {
        setAllUsers(resp.data);
      } else {
        return;
      }
    } catch (error) {
      return;
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleCreateUrl = async () => {
    if (!fileUpload) {
      navigate("/dashboard/invoices/table-invoices");
      return;
    }

    const formData = new FormData();
    formData.append("file", fileUpload);

    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/document/uploadExpense`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      return;
    }
  };

  const handleSave = async (values: typeof initialValues) => {
    setIsSubmitting(true);
    try {
      const fileUrl = await handleCreateUrl();
      if (!fileUrl) throw new Error("Error al cargar el archivo");

      const dataToSend = {
        ...values,
        invoiceUrl: fileUrl,
        taxAmount: cleanCurrency(values.taxAmount),
        totalAmount: cleanCurrency(values.totalAmount),
        details: values.details.map((detail: any) => ({
          ...detail,
          price: cleanCurrency(detail.price),
          total: cleanCurrency(detail.total),
        })),
      };

      const response = await fetchAPI({
        url: `${BASE_API_URL}/expense`,
        method: "POST",
        data: dataToSend,
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "La factura fue creada con éxito.",
          })
        );
        dispatch(setRefreshInvoice());
        handleNavigation();
      } else {
        throw new Error("Error al crear la factura");
      }
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage: "Error al crear la factura. Inténtalo de nuevo.",
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNavigation = () => {
    navigate("/dashboard/invoices/table-invoices");
  };

  useEffect(() => {
    getAllUser();
  }, []);

  return (
    <>
      <div className="px-10">
        <AlertCard
          title="Aviso"
          description="Los datos se han extraído automáticamente y pueden contener errores. Por favor, revisa y corrige la información antes de continuar, ya que este proceso no es completamente preciso."
        />
      </div>
      <div className="px-10 mt-[30px] mb-[60px]">
        <Formik
          initialValues={initialValues}
          validationSchema={createUserSchema}
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({ values }) => (
            <Form>
              <div className="invoice-block">
                <div className="mb-5">
                  <Typography fontSize="text-lg" fontWeight="bold">
                    Información de la Factura
                  </Typography>
                </div>
                <div className="mb-[20px]">
                  <TextField
                    name="project"
                    type="text"
                    label="Proyecto"
                    requested
                  />
                </div>
                <div className="mb-[20px]">
                  <TextField
                    name="invoiceNumber"
                    type="text"
                    label="Número de Factura"
                    requested
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-[20px]">
                  <TextField
                    name="invoiceIssueDate"
                    type="date"
                    label="Fecha de Emisión"
                    requested
                  />
                  <TextField
                    name="invoiceDueDate"
                    type="date"
                    label="Fecha de Vencimiento"
                    requested
                  />
                </div>
              </div>
              <div className="vendor-block mt-6">
                <div className="mb-5">
                  <Typography fontSize="text-lg" fontWeight="bold">
                    Información del Vendedor
                  </Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField
                    name="vendorName"
                    type="text"
                    label="Nombre del Vendedor"
                    requested
                  />
                  <TextField
                    name="vendorNit"
                    type="text"
                    label="NIT del Vendedor"
                  />
                  <TextField
                    name="vendorAddress"
                    type="text"
                    label="Dirección del Vendedor"
                  />
                  <TextField
                    name="vendorPhone"
                    type="text"
                    label="Teléfono del Vendedor"
                  />
                </div>
              </div>
              <div className="tax-total-block mt-6">
                <div className="mb-5">
                  <Typography fontSize="text-lg" fontWeight="bold">
                    Impuestos y Total
                  </Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField
                    name="taxAmount"
                    type="string"
                    label="Monto de Impuestos"
                    requested
                  />
                  <TextField
                    name="totalAmount"
                    type="string"
                    label="Monto Total"
                    requested
                  />
                </div>
              </div>
              <div className="details-block mt-6">
                <div className="mb-5">
                  <Typography fontSize="text-lg" fontWeight="bold">
                    Detalles
                  </Typography>
                </div>
                {values.details?.map((_: any, index: any) => (
                  <div
                    key={index}
                    className="grid grid-cols-1 md:grid-cols-6 gap-4 "
                  >
                    <div className="col-span-3">
                      <TextField
                        name={`details[${index}].item`}
                        type="text"
                        label="Descripción"
                      />
                    </div>
                    <div className="col-span-1">
                      <TextField
                        name={`details[${index}].quantity`}
                        type="text"
                        label="Cantidad"
                      />
                    </div>
                    <div className="col-span-2">
                      <TextField
                        name={`details[${index}].price`}
                        type="text"
                        label="Precio"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="assignment-block mt-6">
                <div className="mb-5">
                  <Typography fontSize="text-lg" fontWeight="bold">
                    Asignación
                  </Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {loadingUsers ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    <FilteredSelect
                      name="assignedTo"
                      label="Asignar a"
                      options={allUsersOptions}
                      placeholder="Selecciona un usuario"
                      className="my-custom-class"
                      requested
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-center mt-8 gap-6">
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  width={200}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    "Crear factura"
                  )}
                </Button>
                <Button
                  onClick={handleNavigation}
                  type="reset"
                  color="cancel"
                  size="medium"
                  width={200}
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateInvoice;
