import { Department } from '../../features/departments/types';

export const findDepartmentLabels = (
  departmentIds: (string | undefined)[],
  departments: Department[]
): string => {
  if (!departmentIds || departmentIds.length === 0) {
    return '';
  }

  const labels = departmentIds
    .filter((id): id is string => !!id)
    .map((id) => {
      const department = departments.find((dept) => dept.departmentId === id);
      return department ? department.departmentName : '';
    });

  return labels.join(', ');
};
