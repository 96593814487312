import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Typography from "../../../../_emailApp/layout/components/Typography";
import TextField from "../../../../_emailApp/layout/components/TextField";
import Button from "../../../../_emailApp/layout/components/Button";
import { useTranslation } from "react-i18next";
import { BASE_API_URL } from "../../../constanst/constantURL";
import { fetchAPI } from "../../../api/fetchAPI";
import { setError } from "../../../../features/errors/errorSlice";
import { useDispatch } from "react-redux";

interface VerifyCodeFormValues {
  code: string;
}

const verifyCodeSchema = Yup.object({
  code: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("El código de verificación es obligatorio"),
});

export function VerifyEmail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const email = location.state?.email;

  const handleSubmit = async (
    values: VerifyCodeFormValues,
    actions: FormikHelpers<VerifyCodeFormValues>
  ) => {
    const url = `${BASE_API_URL}/public/auth/verifyEmailCode?code=${encodeURIComponent(values.code)}`;

    try {
      const response = await fetchAPI({
        url: url,
        method: "GET",
      });

      if (
        response.status === 200 ||
        response.status === 202 ||
        response.status === 204
      ) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage:
              "El código de verificación fue enviado a tu correo electrónico",
          })
        );

        if (response) {
          navigateToNewPassword(email, values.code);
        }
      }

      if (response.status === 400) {
        dispatch(
          setError({
            errorCode: 400,
            errorMessage:
              "El código de verificación ingresado es incorrecto o ha expirado. Por favor, verifica el código e intenta nuevamente.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage:
            "Hubo un problema al verificar el código. Por favor, intenta nuevamente.",
        })
      );
    } finally {
      actions.setSubmitting(false);
    }
  };

  const navigateToNewPassword = (email: string, code: string) => {
    navigate("/auth/new-password", { state: { email, code } });
  };

  return (
    <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
      <Typography as="h1" fontSize="text-lg" textAlign="left" fontWeight="500">
        ¿Tienes un código de verificación?
      </Typography>
      <Typography
        as="h2"
        fontSize="text-sm"
        textAlign="left"
        fontWeight="400"
        className="mt-6 mb-10"
      >
        Ingresa el código de verificación que enviamos a tu correo electrónico.
      </Typography>
      <Formik
        initialValues={{ code: "" }}
        validationSchema={verifyCodeSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <div className="mb-4">
              <TextField
                label="Código de verificación"
                type="text"
                name="code"
                placeholder="Ingresa el código de verificación"
              />
            </div>
            <div className="pt-5 flex justify-end">
              <Typography
                as="span"
                fontSize="text-sm"
                fontWeight="700"
                textAlign="right"
              >
                <Link to={"/auth/login"}>{t("auth.RETURN_TO_LOG")}</Link>
              </Typography>
            </div>
            <div className="flex justify-center mt-12">
              <Button
                type="submit"
                disabled={!isValid}
                color="primary"
                size="medium"
                width={300}
              >
                Verificar código
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
