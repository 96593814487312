export const getStatusLabelColor = (status: string): string => {
  switch (status) {
    case "RECEIVED":
      return "primary";
    case "UNDER_REVIEW":
      return "warning";
    case "IN_PAYMENT_PROCESS":
      return "info";
    case "APPROVED":
      return "success";
    case "REJECTED":
      return "warning";
    case "PAID":
      return "success";
    case "CANCELED":
      return "red-500";
    case "ARCHIVED":
      return "label-primary";
    default:
      return "gray-400";
  }
};
