import React from "react";

interface ProgressBarProps {
  processStage: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ processStage }) => {
  const stateConfig = {
    RECEIVED: { color: "#2691F5", percentage: 25 },
    UNDER_REVIEW: { color: "#FFA800", percentage: 50 },
    IN_PAYMENT_PROCESS: { color: "#F64E60", percentage: 75 },
    FINISHED: { color: "#11B9B2", percentage: 100 },
  };

  const hexToRgba = (hex: string, opacity: number) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const { color, percentage } = stateConfig[
    processStage as keyof typeof stateConfig
  ] || {
    color: "#gray",
    percentage: 0,
  };

  const backgroundColor = hexToRgba(color, 0.2);

  return (
    <div className="w-full rounded-full h-2 my-4" style={{ backgroundColor }}>
      <div
        style={{ width: `${percentage}%`, backgroundColor: color }}
        className={`h-full rounded-full transition-width duration-300`}
      ></div>
    </div>
  );
};

export default ProgressBar;
