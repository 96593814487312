export const TOTAL_PAGES = 20;

export const STATUS_DESCRIPTIONS = {
  TODO: "Por hacer",
  PROCESS: "En proceso",
  REVISION: "En revisión",
  DONE: "Hecho",
};

export const PRIORITY_LEVEL = {
  ALTA: "Alta",
  MEDIA: "Media",
  BAJA: "Baja",
};

export const STATUS = ["TODO", "PROCESS", "REVISION", "DONE"];

export const STATUS_PROCESSSTAGE: Record<string, string> = {
  RECEIVED: "Recibido",
  UNDER_REVIEW: "En revisión",
  IN_PAYMENT_PROCESS: "En proceso de pago",
  APPROVED: "Aprobado",
  REJECTED: "Rechazado",
  PAID: "Pagado",
  CANCELED: "Cancelado",
  ARCHIVED: "Archivado",
};

export const STATUS_DESCRIPTIONS_INVOICE: Record<string, string> = {
  RECEIVED: "Recibido",
  UNDER_REVIEW: "En revisión",
  IN_PAYMENT_PROCESS: "En proceso de pago",
  FINISHED: "Finalizado",
};

export const STATUS_INVOICE = [
  "RECEIVED",
  "UNDER_REVIEW",
  "IN_PAYMENT_PROCESS",
  "FINISHED",
];
