import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { clearError } from "../../../features/errors/errorSlice";
import { IoWarningOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import Typography from "../../../_emailApp/layout/components/Typography";

const ErrorNotification = () => {
  const { errorCode, errorMessage } = useSelector(
    (state: RootState) => state.error
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorCode) {
      const timer = setTimeout(() => {
        dispatch(clearError());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorCode, dispatch]);

  if (!errorCode) return null;

  const Icon = errorCode === 200 ? IoCheckmarkCircleOutline : IoWarningOutline;
  const iconColor = errorCode === 200 ? "text-green-500" : "text-red-500";

  return (
    <div className="fixed top-[42px] left-1/2 transform -translate-x-1/2 shadow-md p-4 flex items-center justify-center rounded-lg bg-white">
      <Icon size={30} className={`mr-3 ${iconColor}`} />
      <Typography
        fontSize="text-sm"
        fontWeight="lighter"
        color="text-label-primary"
      >
        {errorMessage}
      </Typography>
    </div>
  );
};

export default ErrorNotification;
