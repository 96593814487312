import { fetchAPI } from "../../../../../../api/fetchAPI";
import { setError } from "../../../../../../../features/errors/errorSlice";
import { Dispatch } from "redux";
import { BASE_API_URL } from "../../../../../../constanst/constantURL";

export const fetchBoard = async (
  userId?: string,
  isUserRole: boolean = false
) => {
  try {
    const url =
      isUserRole && userId
        ? `${BASE_API_URL}/expense/board/user`
        : userId
          ? `${BASE_API_URL}/expense/board/user/${userId}`
          : `${BASE_API_URL}/expense/board`;

    const response = await fetchAPI<any>({ url, method: "GET" });
    return response.data || [];
  } catch (error) {
    return [];
  }
};

export const saveProgressState = async (
  dispatch: Dispatch,
  invoiceId: string,
  status: string,
  processStage: string
) => {
  try {
    const response = await fetchAPI<any>({
      url: `${BASE_API_URL}/expense/${invoiceId}`,
      method: "PUT",
      data: { invoiceId, status, processStage },
    });

    if (response.status === 200) {
      dispatch(
        setError({
          errorCode: 200,
          errorMessage: "El estado del progreso fue actualizado con éxito.",
        })
      );
      return response.data.data;
    }
  } catch (error) {
    dispatch(
      setError({
        errorCode: 500,
        errorMessage: "Error inesperado. Inténtalo de nuevo.",
      })
    );
  }
};

export const fetchAllUsers = async () => {
  try {
    const response = await fetchAPI<any>({
      url: `${BASE_API_URL}/user`,
      method: "GET",
    });
    return response.data;
  } catch (error) {
    return;
  }
};
