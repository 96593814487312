import React from 'react';
import Select, { SingleValue } from 'react-select';
import 'tailwindcss/tailwind.css';

interface Option {
  value: string;
  label: string;
}

interface FilteredSelectSearchProps {
  options: Option[];
  name: string;
  placeholder?: string;
  className?: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
}

const FilteredSelectSearch: React.FC<FilteredSelectSearchProps> = ({
  label,
  className,
  placeholder,
  options,
  name,
  value,
  onChange,
}) => {
  const handleChange = (option: SingleValue<Option>) => {
    onChange(option?.value || '');
  };

  return (
    <div className={className}>
      {label && (
        <label
          className="font-semibold text-base text-label-primary pb-1"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#F6F7F9',
            padding: '5px',
            border: 'none',
            borderRadius: '10px',
          }),
          placeholder: (provided) => ({
            ...provided,
            color: '#BCBCBC',
            fontSize: '16px',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#1F1F22',
          }),
        }}
        id={name}
        name={name}
        options={options}
        value={options.find((option) => option.value === value)}
        onChange={handleChange}
        placeholder={placeholder}
        classNamePrefix="react-select"
        isClearable
      />
    </div>
  );
};

export default FilteredSelectSearch;
