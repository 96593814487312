import React from 'react';

interface ButtonProps {
  type: 'button' | 'submit' | 'reset';
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'default' | 'primary' | 'cancel';
  size?: 'small' | 'medium' | 'large';
  width?: number | string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type = 'submit',
  variant = 'contained',
  color = 'default',
  size = 'medium',
  width,
  startIcon,
  endIcon,
  children,
  onClick,
  className,
  disabled = false,
}) => {
  const baseClasses = `text-xs rounded-xl inline-flex items-center justify-center font-medium focus:outline-none transition ease-in-out duration-300
    ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'} 
    ${className || ''}`;

  const sizeClasses = {
    small: 'text-xs px-3 py-2',
    medium: 'text-sm px-5 py-2.5',
    large: 'text-base px-6 py-3',
  };

  const colorClasses = {
    default: 'text-black bg-label-primary',
    primary: 'text-white bg-primary hover:bg-primary-500',
    cancel: 'text-label-secundary bg-cancel hover:bg-cancel-500',
  };

  const variantClasses = {
    contained: `${colorClasses[color]} shadow-md`,
    outlined: `border-2 border-label-placeholder text-${color}-500 bg-transparent hover:bg-${color}-100`,
    text: `text-${color}-500 bg-transparent hover:bg-${color}-100`,
    disabled: 'bg-gray-500 border-label-secundary text-label-secundary',
  };

  const classes = `${baseClasses} ${sizeClasses[size]} ${variantClasses[variant]} ${disabled ? variantClasses.disabled : ''} ${disabled && color === 'primary' ? 'bg-primary-500 text-white' : ''}`;

  const style = {
    width: width ? (typeof width === 'number' ? `${width}px` : width) : 'auto',
  };

  return (
    <button
      type={type}
      className={classes}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {startIcon && <span className="mr-2">{startIcon}</span>}
      {children}
      {endIcon && <span className="ml-2">{endIcon}</span>}
    </button>
  );
};

export default Button;
