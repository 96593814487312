import { useSelector } from "react-redux";
import LogoUser from "../LogoUser";
import { RootState } from "../../../../app/store";
import { UserDetails } from "../../../../features/user/types";
import ProfileModal from "./ProfileModal";
import { useEffect, useRef, useState } from "react";
import NotificationModal from "./NotificationModal";
import { IoNotificationsOutline } from "react-icons/io5";

interface NavbarProps {
  toggleSidebar: () => void;
}

export function Navbar({ toggleSidebar }: NavbarProps) {
  const { name, lastName } = useSelector(
    (state: RootState) => state.user?.userDetails as UserDetails
  );

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const profileRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target as Node) &&
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node)
    ) {
      setIsProfileModalOpen(false);
      setIsNotificationModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="fixed top-0 sm:left-64 z-50 w-full lg:w-[calc(100%-16rem)] bg-white py-[10px] shadow-sm">
      <div className="px-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start rtl:justify-end">
            <button
              onClick={toggleSidebar}
              aria-controls="logo-sidebar"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>
          </div>
          <div className="relative flex items-center">
            <div className="flex items-center ms-3">
              <div
                className="mr-1 md:mr-2 mb-4 md:mb-0 relative"
                ref={notificationRef}
              >
                <div
                  className="relative md:ml-6"
                  onClick={() =>
                    setIsNotificationModalOpen(!isNotificationModalOpen)
                  }
                  color="primary"
                >
                  <IoNotificationsOutline size={20} />
                  <span className="absolute top-0 right-0 h-2 w-2 bg-red-500 rounded-full"></span>
                </div>
                {isNotificationModalOpen && (
                  <NotificationModal
                    setIsNotificationModalOpen={setIsNotificationModalOpen}
                  />
                )}
              </div>

              <div
                className="mr-0 md:mr-6 mb-4 md:mb-0 relative"
                ref={profileRef}
              >
                <div
                  onClick={() => setIsProfileModalOpen(!isProfileModalOpen)}
                  className="md:ml-6"
                  color="primary"
                >
                  <LogoUser
                    name={name || ""}
                    lastName={lastName || ""}
                    size={40}
                  />
                </div>
                {isProfileModalOpen && (
                  <ProfileModal name={name || ""} lastName={lastName || ""} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
