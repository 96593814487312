import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthPage from "../module/auth/AuthPage";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import PrivateRoute from "./PrivateRouter";
import { useRoles } from "../hooks/useRoles";

const AppRouter = () => {
  const { getRoleIdsByName } = useRoles();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<AuthPage />} />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute
              allowedRoles={getRoleIdsByName([
                "AppCreator",
                "SuperAdmin",
                "Admin",
                "User",
              ])}
            >
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
