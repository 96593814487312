import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { NavbarDefault } from "../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import LogoUser from "../../../../../_emailApp/layout/components/LogoUser";
import { fetchAllUsers, fetchBoard } from "./service/apiService";
import NoResultFound from "../../../../../_emailApp/layout/components/NoResultFound";
import { UserDetails } from "../../../../../features/user/types";
import { Tabs } from "../../../../../_emailApp/layout/components/Tabs";
import TaskBoard from "./tabs/TaskBoard";
import EmailBoard from "./tabs/EmailBoard";
import { setError } from "../../../../../features/errors/errorSlice";
import { useRoles } from "../../../../hooks/useRoles";

const TableBoard: React.FC = () => {
  const dispatch = useDispatch();

  const refreshAssignments = useSelector(
    (state: RootState) => state.assignments.refreshAssignments
  );
  const { userId } = useSelector(
    (state: RootState) => state.user.userDetails as UserDetails
  );

  const [allUsers, setAllUsers] = useState<UserDetails[]>([]);
  const [userIdSelected, setUserIdSelected] = useState<string>(userId ?? "");
  const [noTasks, setNoTasks] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("Tareas");

  const { getCurrentUserRole } = useRoles();
  const currentUserRole = getCurrentUserRole();
  const role = currentUserRole?.roleName;

  const fetchTasks = useCallback(
    async (userId?: string) => {
      try {
        const isUserRole = role === "User";
        const data = await fetchBoard(userId, isUserRole);
        setNoTasks(data.length === 0);
      } catch (error) {
        dispatch(
          setError({
            errorCode: 500,
            errorMessage: "Error al cargar las tareas. Inténtalo de nuevo.",
          })
        );
        setNoTasks(true);
      }
    },
    [dispatch, role]
  );

  const loadAllUsers = useCallback(async () => {
    try {
      const data = await fetchAllUsers();
      const reorderedUsers = data.sort((a: UserDetails, b: UserDetails) =>
        a.userId === userId ? -1 : 1
      );
      setAllUsers(reorderedUsers);
    } catch (error) {
      return;
    }
  }, [userId]);

  useEffect(() => {
    if (role !== "User") {
      loadAllUsers();
    }
    fetchTasks(userIdSelected);
  }, [refreshAssignments, fetchTasks, userIdSelected, loadAllUsers, role]);

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault title="Tablero Asignaciones" />
      <div className="flex flex-row mb-10 ml-8">
        {role !== "User" &&
          allUsers?.map((item: UserDetails) => (
            <div
              key={item.userId}
              className="flex flex-col items-center relative group"
            >
              <button
                onClick={() => setUserIdSelected(item.userId ?? "")}
                className={`ml-[-15px] transition-transform duration-300 ${
                  userIdSelected === item.userId ? "scale-110 bg-blue-100" : ""
                } rounded-full p-1 hover:bg-blue-200 relative`}
              >
                <LogoUser
                  name={item.name || ""}
                  lastName={item.lastName || ""}
                  size={40}
                />
              </button>
              <div className="absolute top-full mt-1 w-full text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-sm text-primary whitespace-nowrap bg-primary-100 rounded px-2 py-1">
                  {item.name} {item.lastName}
                </span>
              </div>
            </div>
          ))}
      </div>

      {noTasks ? (
        <div>
          <NoResultFound />
        </div>
      ) : (
        <>
          <div className="mb-8">
            <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          </div>
          <div className="mt-5 mx-5">
            {selectedTab === "Tareas" && <TaskBoard userId={userIdSelected} />}
            {selectedTab === "Correos Electrónicos" && (
              <EmailBoard userId={userIdSelected} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TableBoard;
