interface IconDownloadProps {
  className?: string;
}

const IconDepartmentSVG: React.FC<IconDownloadProps> = ({ className }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="28" height="28" rx="2" fill="#24A749" />
      <circle cx="14" cy="14" r="9" fill="white" />
    </svg>
  );
};

export default IconDepartmentSVG;
