import React from "react";
import Typography from "./Typography";

interface AlertCardProps {
  title: string;
  description: string;
}

const AlertCard: React.FC<AlertCardProps> = ({ title, description }) => {
  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      role="alert"
    >
      <Typography fontSize="text-xs" fontWeight="bold" color="text-danger">
        {title}:
      </Typography>
      <Typography
        fontWeight="lighter"
        fontSize="text-xs"
        className="block sm:inline"
        color="text-danger"
      >
        {description}
      </Typography>
    </div>
  );
};

export default AlertCard;
