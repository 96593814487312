import React from "react";
import { ErrorMessage, Field } from "formik";

interface Option {
  value: string;
  label: string;
}

interface CheckboxGroupProps {
  options: Option[];
  name?: string;
  className?: string;
  label?: string;
  requested?: boolean;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  label,
  className,
  options,
  name,
  requested,
}) => {
  return (
    <div className={className}>
      <label
        className="mt-1 font-semibold text-sm text-label-primary"
        htmlFor={name}
      >
        {label}
      </label>
      {requested && (
        <label className="font-semibold text-sm text-danger pl-1">*</label>
      )}
      <ErrorMessage
        name={name || ""}
        component="div"
        className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-3"
      />
      <div className="grid grid-cols-2 gap-4 mt-4 ml-4">
        {options.map((option) => (
          <div key={option.value} className="flex items-center space-x-2">
            <Field
              type="checkbox"
              id={option.value}
              name={name}
              value={option.value}
              className="form-checkbox h-4 w-4"
            />
            <label
              htmlFor={option.value}
              className="text-sm text-label-primary"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
