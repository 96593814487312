import React, { useCallback, useEffect, useState } from "react";
import IconCheckmarkSVG from "../../../../../../_emailApp/assets/svg/IconCheckmarkSVG";
import NoResultFound from "../../../../../../_emailApp/layout/components/NoResultFound";
import { InvoiceStatus } from "../../../../../../features/assignments/types";
import { fetchBoard, saveProgressState } from "./service/apiService";
import InvoiceColumn from "./components/InvoiceColumn";
import InvoiceCard from "./components/InvoiceCard";
import { ModalInvoice } from "./components/ModalInvoice";
import { STATUS_DESCRIPTIONS_INVOICE } from "../../../../../constanst/constants";
import { useDispatch } from "react-redux";
import { useRoles } from "../../../../../hooks/useRoles";
interface InvoiceBoardProps {
  userId: string;
}

const InvoiceBoard: React.FC<InvoiceBoardProps> = ({ userId }) => {
  const dispatch = useDispatch();

  const [invoices, setInvoices] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any | null>(null);
  const [noInvoices, setNoInvoices] = useState<boolean>(false);

  const { getCurrentUserRole } = useRoles();
  const currentUserRole = getCurrentUserRole();
  const role = currentUserRole?.roleName;

  const fetchInvoices = useCallback(async () => {
    try {
      const isUserRole = role === "User";
      const data = await fetchBoard(userId, isUserRole);
      if (data.length === 0) {
        setNoInvoices(true);
      } else {
        setNoInvoices(false);
        setInvoices(data);
      }
    } catch (error) {
      setNoInvoices(true);
    }
  }, [userId, setInvoices, setNoInvoices]);

  const handleSave = () => {
    fetchInvoices();
  };

  const handleSaveProgressState = async (
    invoiceId: string,
    status: string,
    processStage: string
  ) => {
    try {
      await saveProgressState(dispatch, invoiceId, status, processStage);
      fetchInvoices();
    } catch (error) {
      return;
    }
  };

  const handleModalOpen = (invoice: any) => {
    setSelectedInvoice(invoice);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedInvoice(null);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    invoice: any
  ) => {
    if (invoice?.processStage === "FINISHED") {
      e.preventDefault();
      return;
    }
    e.dataTransfer.setData("invoiceId", invoice.invoiceId || "");
  };

  const handleDrop =
    (processStage: InvoiceStatus) =>
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const invoiceId = e.dataTransfer.getData("invoiceId");

      const dataInvoice = invoices.find(
        (item) => item.invoiceId === invoiceId
      ) as any;

      if (processStage === "UNDER_REVIEW" || processStage === "FINISHED") {
        handleModalOpen(dataInvoice);
      } else {
        const status = processStage;
        await handleSaveProgressState(invoiceId, processStage, status);
      }
    };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  return (
    <div>
      {noInvoices ? (
        <NoResultFound />
      ) : (
        <div className="flex flex-row justify-between gap-5 overflow-x-auto md:overflow-x-visible">
          {Object.entries(STATUS_DESCRIPTIONS_INVOICE).map(
            ([processStage, title]) => {
              const filteredInvoices =
                invoices?.filter(
                  (invoice) => invoice?.processStage === processStage
                ) || [];
              return (
                <InvoiceColumn
                  key={processStage}
                  titleColumn={
                    <div className="flex justify-between items-center w-full">
                      <span>{title}</span>
                      <div className="flex">
                        {processStage === "FINISHED" && (
                          <IconCheckmarkSVG width={20} className="mr-3" />
                        )}
                        <span className="text-sm">
                          {filteredInvoices.length}
                        </span>
                      </div>
                    </div>
                  }
                  onDrop={handleDrop(processStage as InvoiceStatus)}
                  onDragOver={handleDragOver}
                >
                  {filteredInvoices.map((invoice) => (
                    <InvoiceCard
                      key={invoice.invoiceId}
                      onDragStart={(e: any) => handleDragStart(e, invoice)}
                      onClick={() => handleModalOpen(invoice)}
                      {...invoice}
                    />
                  ))}
                </InvoiceColumn>
              );
            }
          )}
        </div>
      )}
      {isModalOpen && selectedInvoice && (
        <ModalInvoice
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSave={handleSave}
          {...selectedInvoice}
        />
      )}
    </div>
  );
};

export default InvoiceBoard;
