import { Outlet } from "react-router-dom";
import LogoWhiteSVG from "../../../_emailApp/assets/svg/LogoWhiteSVG";
import LogoSVG from "../../../_emailApp/assets/svg/LogoSVG";
import { useEffect } from "react";

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  return (
    <div className="flex flex-col md:flex-row flex-column-fluid h-100">
      <div className="flex flex-wrap w-full md:w-1/2">
        <div className="flex flex-col w-full">
          <div className="flex justify-center md:justify-start pt-12 md:pl-12 md:-mb-24">
            <LogoWhiteSVG className="w-20 h-auto" />
          </div>
          <Outlet />
        </div>
      </div>
      <div
        className="hidden md:flex w-1/2 bg-backgroundSidebar h-screen justify-center items-center"
        style={{ borderRadius: "80px 0 0 80px" }}
      >
        <LogoSVG className="w-[150px] animate-slide-in" />
      </div>
    </div>
  );
};

export { AuthLayout };
