import { useSelector } from 'react-redux';
import { RootState } from '../store';

const useDepartmentOptions = () => {
  const departments = useSelector(
    (state: RootState) => state.departments?.departments
  );

  const departmentOptions =
    departments?.map((department) => ({
      value: department.departmentId ?? '',
      label: department.departmentName ?? '',
    })) ?? [];

  return departmentOptions;
};

export default useDepartmentOptions;
