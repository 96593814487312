import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from './types';

interface NotificationState {
  notifications?: Notification[] | null;
}

const initialState: NotificationState = {
  notifications: null,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
  },
});

export const { setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
