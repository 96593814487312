import { createSlice } from '@reduxjs/toolkit';

interface InvoiceState {
  refreshInvoices: boolean;
}

const initialState: InvoiceState = {
  refreshInvoices: false,
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setRefreshInvoice(state) {
      state.refreshInvoices = !state.refreshInvoices;
    },
  },
});

export const { setRefreshInvoice } =invoiceSlice.actions;
export default invoiceSlice.reducer;
