import { useSelector } from "react-redux";
import { RootState } from "../store";

interface Role {
  roleId: string;
  roleName: string;
  roleDescription: string;
  heritage: number;
}

const roleTranslations: { [key: string]: string } = {
  User: "Usuario",
  AppCreator: "Creador de la App",
  Admin: "Administrador",
  SuperAdmin: "Super Administrador",
};

export const useRoles = () => {
  const roles = useSelector((state: RootState) => state.roles.roles as Role[]);
  const currentUserRoleId = useSelector(
    (state: RootState) => state.auth.userRole
  );

  const getRoleIdsByName = (roleNames: string[]): string[] => {
    return roles
      .filter((role) => roleNames.includes(role.roleName))
      .map((role) => role.roleId);
  };

  const getTranslatedRoleOptions = () => {
    return roles.map((role) => ({
      value: role.roleId,
      label: roleTranslations[role.roleName] || role.roleName,
    }));
  };

  const getCurrentUserRole = (): Role | undefined => {
    return roles.find((role) => role.roleId === currentUserRoleId);
  };

  return {
    roles,
    getRoleIdsByName,
    getTranslatedRoleOptions,
    getCurrentUserRole,
  };
};
