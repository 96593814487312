import { Link, useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Typography from "../../../../_emailApp/layout/components/Typography";
import TextField from "../../../../_emailApp/layout/components/TextField";
import Button from "../../../../_emailApp/layout/components/Button";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { fetchAPI } from "../../../api/fetchAPI";
import { setRoles } from "../../../../features/roles/roleSlice";
import { Role } from "../../../../features/roles/types";
import { UserDetails } from "../../../../features/user/types";
import { useAuth } from "../hook/useAuth";
import { setUserDetails } from "../../../../features/user/userSlice";
import { setNotifications } from "../../../../features/notifications/notificationsSlice";
import { Notification } from "../../../../features/notifications/types";
import { setDepartments } from "../../../../features/departments/departmentSlice";
import { Department } from "../../../../features/departments/types";
import { BASE_API_URL } from "../../../constanst/constantURL";

interface LoginFormValues {
  email: string;
  password: string;
}

const loginSchema = Yup.object({
  email: Yup.string()
    .email("Formato de email inválido")
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("El email es obligatorio"),
  password: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("La contraseña es obligatoria"),
});

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login } = useAuth();
  const [rolUser, setRolUser] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (rolUser !== null) {
      login(rolUser);
      handleLogin();
    }
  }, [rolUser, login, navigate]);

  const handleLogin = () => navigate("/dashboard/assignments/table-board");

  const fetchUserDetails = async () => {
    const response = await fetchAPI<UserDetails>({
      url: `${BASE_API_URL}/user/userDetail/info`,
      method: "GET",
    });

    if (response.data) {
      if (isMounted.current) {
        setRolUser(response.data.roleId || "null");
        dispatch(setUserDetails(response.data));
      }
    }
  };

  const fetchRoles = async () => {
    const response = await fetchAPI<Role[]>({
      url: `${BASE_API_URL}/role`,
      method: "GET",
    });

    if (response.data) {
      dispatch(setRoles(response.data));
    }
  };

  const fetchDepartments = async () => {
    const response = await fetchAPI<Department[]>({
      url: `${BASE_API_URL}/department`,
      method: "GET",
    });

    if (response.data && Array.isArray(response.data)) {
      dispatch(setDepartments(response.data));
    }
  };

  const fetchNotifications = async () => {
    const response = await fetchAPI<Notification[]>({
      url: `${BASE_API_URL}/assignation/board`,
      method: "GET",
    });

    if (response.data) {
      dispatch(setNotifications(response.data));
    }
  };

  const handleSubmit = async (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>
  ) => {
    const url = `${BASE_API_URL}/public/auth/login`;
    try {
      const response = await axios.post(url, values);
      if (response.data && response.data.token) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        await Promise.all([
          fetchUserDetails(),
          fetchRoles(),
          fetchDepartments(),
          fetchNotifications(),
        ]);
      } else {
        throw new Error("No token received");
      }
    } catch (error) {
      setErrorMessage(
        "Error al iniciar sesión. Por favor verifique sus credenciales."
      );
      actions.setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col justify-center md:justify-start my-auto pt-10 pb-5 md:pt-0 px-8 md:px-24 lg:px-32">
      <Typography
        as="h1"
        fontSize="text-xl"
        textAlign="center"
        fontWeight="500"
        className="mb-10"
      >
        {t("auth.WELCOME")}
      </Typography>
      {errorMessage && (
        <div className="mb-6 flex items-center leading-normal text-danger rounded-lg">
          <IoCloseCircleOutline size={20} />
          <Typography
            as="h2"
            fontSize="text-sm"
            textAlign="left"
            fontWeight="500"
            color="danger"
            className="ml-2"
          >
            {errorMessage}
          </Typography>
        </div>
      )}
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <div className="mb-4">
              <TextField
                label="Correo Electrónico"
                type="email"
                name="email"
                placeholder="Ingresa Correo Electrónico"
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Contraseña"
                type="password"
                name="password"
                placeholder="Ingresa la contraseña"
              />
            </div>
            <div className="pt-5 flex justify-end">
              <Typography
                as="span"
                fontSize="text-sm"
                fontWeight="700"
                textAlign="right"
              >
                <Link to="/auth/forgot-password">
                  {t("auth.FORGOT_PASSWORD")}
                </Link>
              </Typography>
            </div>
            <div className="flex justify-center mt-12">
              <Button
                type="submit"
                disabled={!isValid}
                color="primary"
                size="medium"
                width={200}
              >
                {t("auth.LOGIN")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
