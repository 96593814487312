import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Department } from './types';

interface DepartmentState {
  departments?: Department[] | null;
  refreshDepartments: boolean;
}

const initialState: DepartmentState = {
  departments: null,
  refreshDepartments: false,
};

const departmentSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload;
    },
    setRefreshDepartments(state) {
      state.refreshDepartments = !state.refreshDepartments;
    },
  },
});

export const { setDepartments, setRefreshDepartments } =
  departmentSlice.actions;
export default departmentSlice.reducer;
