interface IconLowestSVGProps {
  className?: string;
  width?: number;
}

const IconHighestSVG: React.FC<IconLowestSVGProps> = ({ className, width }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.4787 7.89999C2.9787 8.19999 2.3787 7.99999 2.0787 7.49999C1.7787 6.99999 1.9787 6.49999 2.4787 6.19999L7.4787 3.19999C7.7787 2.99999 8.1787 2.99999 8.4787 3.19999L13.4787 6.19999C13.9787 6.49999 14.0787 7.09999 13.7787 7.59999C13.5787 7.99999 12.9787 8.19999 12.4787 7.89999L7.9787 5.19999L3.4787 7.89999Z"
        fill="#FF5630"
      />
      <path
        d="M3.4787 12.2C2.9787 12.5 2.3787 12.4 2.0787 11.9C1.7787 11.4 1.9787 10.8 2.4787 10.5L7.4787 7.49998C7.7787 7.29998 8.1787 7.29998 8.4787 7.49998L13.4787 10.5C13.9787 10.8 14.0787 11.4 13.7787 11.9C13.4787 12.4 12.8787 12.5 12.3787 12.2L7.9787 9.49998L3.4787 12.2Z"
        fill="#FF7452"
      />
    </svg>
  );
};

export default IconHighestSVG;
