import React from 'react';
import { IoMailOutline, IoGridOutline } from 'react-icons/io5';
const tabs = [
  { name: 'Tareas', icon: IoGridOutline },
  { name: 'Correos Electrónicos', icon: IoMailOutline },
];

interface TabsProps {
  selectedTab: string;
  setSelectedTab: (tabName: string) => void;
}

export const Tabs: React.FC<TabsProps> = ({ selectedTab, setSelectedTab }) => {
  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  return (
    <div className="border-b border-gray-200 dark:border-gray-300">
      <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {tabs.map((tab) => {
          const Icon = tab.icon;
          return (
            <li className="me-2" key={tab.name}>
              <button
                onClick={() => handleTabClick(tab.name)}
                className={`inline-flex items-center justify-center p-4 border-b-2 ${
                  selectedTab === tab.name
                    ? 'text-primary border-primary'
                    : 'border-transparent hover:text-menu-button hover:border-menu-button'
                } rounded-t-lg dark:hover:text-menu-buttongroup`}
              >
                <Icon
                  className={`w-4 h-4 me-2 ${
                    selectedTab === tab.name
                      ? 'text-primary dark:text-primary'
                      : 'text-menu-button group-hover:text-menu-button dark:text-menu-button dark:group-hover:text-menu-button'
                  }`}
                />
                {tab.name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
