import { ErrorMessage, Field } from "formik";
import React from "react";

interface Option {
  value: string;
  label: string;
}

interface RadioGroupProps {
  name: string;
  label?: string;
  className?: string;
  options: Option[];
  requested?: boolean;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  label,
  className,
  options,
  requested,
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <div>
        <label
          className="mt-1 font-semibold text-base text-label-primary"
          htmlFor={name}
        >
          {label}
        </label>
        {requested && (
          <label className="font-semibold text-sm text-danger pl-1">*</label>
        )}
      </div>
      {options?.map((option) => (
        <label key={option.value}>
          <Field
            type="radio"
            name={name}
            value={option.value}
            className="form-radio h-3 w-3 text-label-primary rounded"
          />
          <label className="font-normal text-sm text-label-primary ml-3">
            {option.label}
          </label>
          <ErrorMessage
            name={name}
            component="div"
            className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-3"
          />
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
