import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "../../../../../_emailApp/layout/components/TextField";
import { NavbarDefault } from "../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import SelectField from "../../../../../_emailApp/layout/components/SelectField";
import Button from "../../../../../_emailApp/layout/components/Button";
import CheckboxGroup from "../../../../../_emailApp/layout/components/CheckboxGroup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { Department } from "../../../../../features/departments/types";
import { fetchAPI } from "../../../../api/fetchAPI";
import { User } from "./TableUser";
import { setRefreshUsers } from "../../../../../features/user/userSlice";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { setError } from "../../../../../features/errors/errorSlice";
import { useRoles } from "../../../../hooks/useRoles";
import { BASE_API_URL } from "../../../../constanst/constantURL";

const createUserSchema = Yup.object({
  name: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("El nombre es requerido"),
  lastName: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("El apellido es requerido"),
  email: Yup.string()
    .email("Formato de email inválido")
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("El email es requerido"),
  roleId: Yup.string().required("El rol es requerido"),
  departmentIds: Yup.array()
    .of(Yup.string())
    .min(1, "Selecciona al menos un departamento")
    .required("La selección de áreas es requerida"),
});

export const CreateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getTranslatedRoleOptions } = useRoles();
  const departments = useSelector(
    (state: RootState) => state.departments.departments as Department[]
  );

  const idEditUser = location?.state?.user?.userId;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const user = location?.state?.user || {
    name: "",
    lastName: "",
    email: "",
    roleId: "",
    departmentIds: [],
  };

  const rolesOptions = getTranslatedRoleOptions();

  const departementOptions = departments?.map((dept) => ({
    value: dept.departmentId ?? "",
    label: dept.departmentName ?? "",
  }));

  const handleSave = async (data: User) => {
    setIsSubmitting(true);
    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/user`,
        method: "POST",
        data: data,
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "El usuario fue creado con éxito",
          })
        );
      }

      dispatch(setRefreshUsers());
      handleNavigation();
    } catch (error) {
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdate = async (data: User) => {
    setIsSubmitting(true);
    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/user/${idEditUser}`,
        method: "PUT",
        data: data,
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "El usuario fue actualizado con éxito",
          })
        );
      }

      dispatch(setRefreshUsers());
      handleNavigation();
    } catch (error) {
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNavigation = () => {
    navigate("/dashboard/users/table-users");
  };

  const handleTrim = (values: User) => {
    return {
      ...values,
      name: values.name.trim(),
      lastName: values.lastName.trim(),
      email: values.email.trim(),
    };
  };

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault
        title={idEditUser ? "Actualizar Usuario" : "Crear Usuario"}
      />
      <Formik
        initialValues={user}
        validationSchema={createUserSchema}
        onSubmit={(values, { resetForm }) => {
          const trimmedValues = handleTrim(values);
          if (idEditUser) {
            handleUpdate(trimmedValues);
          } else {
            handleSave(trimmedValues);
          }
          resetForm();
        }}
      >
        {() => (
          <Form className="flex flex-col pt-3 md:pt-4 mx-4 sm:mx-24">
            <div className="mb-4">
              <TextField
                label="Nombre"
                type="text"
                name="name"
                placeholder="Ingresa el nombre"
                requested
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Apellido"
                type="text"
                name="lastName"
                placeholder="Ingresa el apellido"
                requested
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Correo electrónico"
                type="text"
                name="email"
                placeholder="Ingresa el correo electrónico"
                requested
              />
            </div>
            <div className="mb-4">
              <SelectField
                label="Rol"
                placeholder="Selecciona un rol"
                name="roleId"
                options={rolesOptions}
                requested
              />
            </div>
            <div className="mb-4">
              <CheckboxGroup
                label="Departamento"
                className="mr-2 leading-tight"
                name="departmentIds"
                options={departementOptions}
                requested
              />
            </div>
            <div className="flex justify-center m-5 gap-6">
              <Button type="submit" color="primary" size="medium" width={200}>
                {isSubmitting ? (
                  <FaSpinner className="animate-spin" />
                ) : idEditUser ? (
                  "Actualizar Usuario"
                ) : (
                  "Crear Usuario"
                )}
              </Button>
              <Button
                onClick={handleNavigation}
                type="reset"
                color="cancel"
                size="medium"
                width={200}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
