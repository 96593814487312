import axios, { AxiosRequestConfig, Method } from "axios";
import { logout } from "../../features/auth/authSlice";
import store from "../store";
import { setError } from "../../features/errors/errorSlice";

interface FetchParams {
  url: string;
  method: Method;
  data?: any;
}

interface FetchResponse<T> {
  data: T | null;
  error: string | null;
  status?: number;
}

const getErrorMessage = (status: number): string => {
  switch (status) {
    case 200:
      return "Operación exitosa";
    case 226:
      return "Recurso ya creado o en uso";
    case 401:
      return "Nombre de usuario inválido o solicitud incorrecta";
    case 403:
      return "Acción no permitida";
    case 404:
      return "Recurso no encontrado";
    case 409:
      return "Conflicto: El recurso tiene dependencias";
    case 415:
      return "Tipo de datos no soportado";
    case 500:
    default:
      return "Error inesperado del servidor";
  }
};

export const fetchAPI = async <T>({
  url,
  method,
  data,
}: FetchParams): Promise<FetchResponse<T>> => {
  const authToken = localStorage.getItem("token");
  const isFormData = data instanceof FormData;

  const config: AxiosRequestConfig = {
    method: method,
    url: url,
    headers: {
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      ...(isFormData ? {} : { "Content-Type": "application/json" }),
    },
    data: data || {},
  };

  try {
    const result = await axios(config);
    return {
      data: result.data,
      error: null,
      status: result.status,
    };
  } catch (error: any) {
    const status = error.response?.status;
    if (status) {
      const errorMessage = getErrorMessage(status);
      store.dispatch(setError({ errorCode: status, errorMessage }));

      if (status === 401) {
        store.dispatch(logout());
      }
    }

    return {
      data: null,
      error: error.response?.data?.message || "Error desconocido",
      status: error.response?.status,
    };
  }
};
