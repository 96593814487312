/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "../../../../../../_emailApp/layout/components/Modal";
import Typography from "../../../../../../_emailApp/layout/components/Typography";
import Button from "../../../../../../_emailApp/layout/components/Button";
import IconDepartmentSVG from "../../../../../../_emailApp/assets/svg/IconDepartmentSVG";
import LogoUser from "../../../../../../_emailApp/layout/components/LogoUser";
import IconHighestSVG from "../../../../../../_emailApp/assets/svg/IconHighestSVG";
import { formatDate } from "../../../../../utils/transformDate";
import {
  PRIORITY_LEVEL,
  STATUS,
  STATUS_DESCRIPTIONS,
} from "../../../../../constanst/constants";
import { Assignments } from "../../../../../../features/assignments/types";
import {
  IoArrowForwardOutline,
  IoCloudUploadOutline,
  IoDocumentTextOutline,
  IoWarningOutline,
} from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { User } from "../../users/TableUser";
import { fetchAPI } from "../../../../../api/fetchAPI";
import FilteredSelectSearch from "../../../../../../_emailApp/layout/components/FilteredSelectSeach";
import IconCheckmarkOutlineSVG from "../../../../../../_emailApp/assets/svg/IconCheckmarkOutlineSVG";
import { setRefreshAssignments } from "../../../../../../features/assignments/assignmentSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { UserDetails } from "../../../../../../features/user/types";
import IconMediumSVG from "../../../../../../_emailApp/assets/svg/IconMediumSVG";
import IconLowestSVG from "../../../../../../_emailApp/assets/svg/IconLowestSVG";
import clsx from "clsx";
import { setError } from "../../../../../../features/errors/errorSlice";
import { BASE_API_URL } from "../../../../../constanst/constantURL";

type ModalAssignmentsProps = Assignments & {
  isOpen: boolean;
  onClose: () => void;
  onSave?: () => void;
};

const getPriorityIcon = (priorityLevel: string) => {
  switch (priorityLevel) {
    case PRIORITY_LEVEL.ALTA:
      return <IconHighestSVG width={22} />;
    case PRIORITY_LEVEL.MEDIA:
      return <IconMediumSVG width={22} />;
    case PRIORITY_LEVEL.BAJA:
      return <IconLowestSVG width={22} />;
    default:
      return null;
  }
};

export const ModalAssignments: React.FC<ModalAssignmentsProps> = ({
  assignationId,
  isOpen,
  onClose,
  assignationCode,
  subject,
  title,
  dispatchAt,
  reviewer,
  dueDateAt,
  priorityLevel,
  estimatedTime,
  assignedTo,
  processStage,
  assignationFileUrl,
  assignationResponseFileUrl,
  manually,
  status,
  onSave,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector(
    (state: RootState) => state.user.userDetails as UserDetails
  );

  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [buttonText, setButtonText] = useState<string>("Siguiente etapa");
  const [reviewerSelect, setReviewerSelect] = useState<string>("");
  const [reviewed, setReviewed] = useState<boolean>(false);

  const getClassByState = (state: string): string => {
    const numericState = Number(state);

    if (numericState < 0) {
      return "bg-danger-200 text-danger";
    } else if (numericState > 5) {
      return "bg-info-200 text-info";
    } else if (numericState >= 1 && numericState <= 5) {
      return "bg-warning-200 text-warning";
    } else {
      return "bg-success-200 text-success";
    }
  };

  const allUsersOptions = allUsers
    ?.filter((user: User) => user.userId !== userId)
    .map((user: User) => ({
      value: user.userId ?? "",
      label: `${user.name} ${user.lastName}`,
    }));

  const getAllUsers = async () => {
    try {
      const resp = await fetchAPI({
        url: `${BASE_API_URL}/user`,
        method: "GET",
      });

      if (
        Array.isArray(resp.data) &&
        resp.data.every((item) => typeof item.userId !== "undefined")
      ) {
        setAllUsers(resp.data);
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const getNextAssignmentProcessStage = (
    stage: (typeof STATUS)[number]
  ): string => {
    const currentIndex = STATUS.indexOf(stage);
    const nextStage = STATUS[currentIndex + 1] || "DONE";
    return nextStage;
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const nextStage = getNextAssignmentProcessStage(
      processStage as (typeof STATUS)[number]
    );

    const requestData: any = {
      assignationId: assignationId,
      stage: nextStage,
    };

    if (reviewerSelect) {
      requestData.reviewer = reviewerSelect;
    }

    if (reviewed) {
      requestData.reviewed = true;
    }

    try {
      const response = await fetchAPI<any>({
        url: `${BASE_API_URL}/assignation/board/${assignationId}`,
        method: "PUT",
        data: requestData,
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "La asignación fue creada con éxito",
          })
        );
      }

      dispatch(setRefreshAssignments());
      if (onSave) {
        onSave();
      }
      onClose();
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage: "Error inesperado. Inténtalo de nuevo.",
        })
      );
    }
  };

  const onLookUpDocument = () => {
    window.open(assignationFileUrl || "", "_blank");
  };

  const onUploadResponse = () => {
    onClose();
    navigate("/dashboard/assignments/table-assignments", {
      state: { isResponseDocument: true, taskId: assignationId },
    });
  };

  const onLookUpDResponse = () => {
    window.open(assignationResponseFileUrl || "", "_blank");
  };

  const handleCheckbox = () => {
    if (processStage === "REVISION") {
      setReviewed(!reviewed);
    }
    return;
  };

  const isFormValid = () => {
    let data = true;
    if (
      processStage === "DONE" ||
      (processStage === "PROCESS" && !reviewerSelect) ||
      (processStage === "REVISION" && !reviewed)
    ) {
      data = false;
    }
    return data;
  };

  useEffect(() => {
    if (processStage === "DONE") {
      setReviewed(true);
    }
    getAllUsers();
  }, [processStage, reviewed]);

  useEffect(() => {
    if (processStage) {
      const nextStageDescription = getNextAssignmentProcessStage(
        processStage as (typeof STATUS)[number]
      );
      setButtonText(
        STATUS_DESCRIPTIONS[
          nextStageDescription as keyof typeof STATUS_DESCRIPTIONS
        ]
      );
    }
  }, [processStage]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex">
        <div className="w-3/4 sm:pr-8">
          <div className="flex">
            <IconDepartmentSVG className="w-5 mr-2" />
            <Typography
              as="h2"
              fontWeight="bold"
              display="inline"
              fontSize="text-lg"
            >
              {assignationCode?.toUpperCase()}
            </Typography>
            <div className="flex ml-5">
              <div
                className={clsx(
                  "rounded-full px-[20px] text-sm",
                  getClassByState(status || ""),
                  "flex items-center h-full"
                )}
              >
                {`${status} ${status === "1" ? "día" : "días"}`}
              </div>
            </div>
          </div>
          {!manually && (
            <div className="my-3">
              <button
                onClick={onLookUpDocument}
                className="mr-3 border border-1 p-2 bg-background rounded-lg hover:bg-primary-500 hover:text-white transition duration-200"
              >
                <IoDocumentTextOutline size={20} />
              </button>
              <button
                onClick={onUploadResponse}
                className="mr-3 border border-1 p-2 bg-background rounded-lg hover:bg-primary-500 hover:text-white transition duration-200"
              >
                <IoCloudUploadOutline size={20} />
              </button>
              {assignationResponseFileUrl && (
                <button
                  onClick={onLookUpDResponse}
                  className="mr-3 border border-1 p-2 bg-background rounded-lg hover:bg-primary-500 hover:text-white transition duration-200"
                >
                  <FiSend size={18} />
                </button>
              )}
            </div>
          )}
          <Typography
            as="h3"
            fontWeight="bold"
            fontSize="text-xl"
            className="mt-4"
          >
            {title}
          </Typography>
          <Typography as="h3" fontWeight="normal" fontSize="text-base">
            {subject}
          </Typography>
          <div className="flex mt-4">
            <div className="flex flex-1 flex-col">
              <Typography fontWeight="bolder" as="h3" fontSize="text-sm">
                Fecha de Creación:
              </Typography>
              <Typography as="h3" fontWeight="normal" fontSize="text-sm">
                {formatDate(dispatchAt || "")}
              </Typography>
            </div>
            <div className="flex flex-1 flex-col">
              <Typography fontWeight="bolder" as="h3" fontSize="text-sm">
                Fecha de Finalización:
              </Typography>
              <Typography as="h3" fontWeight="normal" fontSize="text-sm">
                {formatDate(dueDateAt || "")}
              </Typography>
            </div>
          </div>
          <div className="flex w-[230px] mt-4">
            {processStage === "PROCESS" && (
              <div className="flex flex-1 flex-col">
                <Typography
                  fontWeight="bolder"
                  as="h3"
                  fontSize="text-sm"
                  className="pb-1"
                >
                  Asignar a:
                </Typography>
                <FilteredSelectSearch
                  name="reviewerSelect"
                  label=""
                  options={allUsersOptions}
                  placeholder="Asignar a"
                  className="my-custom-class"
                  value={reviewerSelect}
                  onChange={setReviewerSelect}
                />
              </div>
            )}
          </div>
          {!reviewerSelect && processStage === "PROCESS" && (
            <div className="flex mt-[10px] items-center">
              <IoWarningOutline color="#F64E60" />
              <Typography fontWeight="600" fontSize="text-sm" className="ml-1">
                Es necesario asignar un responsable para continuar con esta
                tarea
              </Typography>
            </div>
          )}
          {(processStage === "REVISION" || processStage === "DONE") && (
            <div
              className="flex flex-1 flex-row items-center mt-4"
              onClick={handleCheckbox}
            >
              <IconCheckmarkOutlineSVG width={25} isDone={reviewed} />
              <Typography
                fontWeight="bolder"
                className="ml-2"
                as="h3"
                fontSize="text-sm"
              >
                Aprovado
              </Typography>
            </div>
          )}
          {!reviewed && processStage === "REVISION" && (
            <div className="flex mt-[10px] items-center">
              <IoWarningOutline color="#F64E60" />
              <Typography fontWeight="600" fontSize="text-sm" className="ml-1">
                Es necesario marcar como aprobado para finalizar la tarea
              </Typography>
            </div>
          )}
        </div>
        <div className="flex w-1/4">
          <div className="flex justify-center items-center">
            <form onSubmit={handleSave}>
              {processStage !== "DONE" && (
                <>
                  <div className="flex items-center mb-2">
                    <IoArrowForwardOutline />
                    <Typography
                      fontWeight="bolder"
                      as="h3"
                      fontSize="text-sm"
                      className="pl-1"
                    >
                      Próximo paso
                    </Typography>
                  </div>
                  <Button
                    type="submit"
                    disabled={!isFormValid()}
                    color="primary"
                    size="medium"
                    className="mb-5"
                    width="150px"
                  >
                    {buttonText}
                  </Button>
                </>
              )}
              <div className="flex mb-4">
                {typeof assignedTo === "object" && assignedTo !== null ? (
                  <>
                    <LogoUser
                      name={assignedTo.name || ""}
                      lastName={assignedTo.lastName || ""}
                      size={40}
                    />
                    <div className="ml-3">
                      <Typography
                        as="h3"
                        fontSize="text-sm"
                        fontWeight="bolder"
                      >
                        {assignedTo.name}
                      </Typography>
                      <Typography as="h3" fontSize="text-sm">
                        {assignedTo.lastName}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <Typography as="h3" fontSize="text-sm">
                    Asignado a: {assignedTo}
                  </Typography>
                )}
              </div>
              <div className="mb-4">
                <Typography
                  fontWeight="bolder"
                  as="h3"
                  fontSize="text-sm"
                  className="pb-1"
                >
                  Prioridad
                </Typography>
                <div className="flex items-center">
                  <div className="mr-2">
                    {getPriorityIcon(priorityLevel || "")}
                  </div>
                  <Typography as="h3" fontWeight="normal" fontSize="text-sm">
                    {priorityLevel}
                  </Typography>
                </div>
              </div>
              <div className="mb-4">
                <Typography
                  fontWeight="bolder"
                  as="h3"
                  fontSize="text-sm"
                  className="pb-1"
                >
                  Estimación
                </Typography>
                <Typography as="h3" fontWeight="normal" fontSize="text-sm">
                  {`${estimatedTime} ${estimatedTime === "1" || estimatedTime === "-1" ? "día" : "días"}`}
                </Typography>
              </div>
              {reviewer && (
                <div className="flex mb-4">
                  {typeof reviewer === "object" && reviewer !== null && (
                    <div className="flex flex-col">
                      <Typography
                        fontWeight="bolder"
                        as="h3"
                        fontSize="text-sm"
                        className="pb-1"
                      >
                        Asignación
                      </Typography>
                      <div className="flex flex-row">
                        <LogoUser
                          name={reviewer.name || ""}
                          lastName={reviewer.lastName || ""}
                          size={40}
                        />
                        <div className="ml-3">
                          <Typography
                            as="h3"
                            fontSize="text-sm"
                            fontWeight="bolder"
                          >
                            {reviewer.name}
                          </Typography>
                          <Typography as="h3" fontSize="text-sm">
                            {reviewer.lastName}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
