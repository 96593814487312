import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails } from './types';

interface UserState {
  userDetails: UserDetails | null;
  isAuthenticated: boolean;
  userRole: string;
  refreshUsers: boolean;
}

const initialState: UserState = {
  userDetails: null,
  isAuthenticated: false,
  userRole: '',
  refreshUsers: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    setAuthentication(state, action) {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.userRole = action.payload.userRole;
    },
    setRefreshUsers(state) {
      state.refreshUsers = !state.refreshUsers;
    },
  },
});

export const { setUserDetails, setAuthentication, setRefreshUsers } =
  userSlice.actions;

export default userSlice.reducer;
