import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import SidebarSubItem from "./SidebarSubItem";

interface SidebarItemProps {
  item: {
    id: number;
    label: string;
    icon: JSX.Element;
    to?: string;
    subItems?: { id: number; label: string; to: string }[];
  };
  activeIndex: number | null;
  selectedItem: number | null;
  selectedSubItem: string | null;
  onToggle: (index: number) => void;
  onItemClick: (to?: string) => void;
  onSubItemClick: (subItem: { label: string; to: string }) => void;
  index: number;
}

const SidebarItem = ({
  item,
  activeIndex,
  selectedItem,
  selectedSubItem,
  onToggle,
  onItemClick,
  onSubItemClick,
  index,
}: SidebarItemProps) => {
  return (
    <div>
      <button
        onClick={() => {
          if (!item.subItems) {
            onItemClick(item.to);
          }
          onToggle(index);
        }}
        className={`flex mb-[14px] justify-between items-center w-full text-left p-2 rounded-lg 
          hover:bg-backgroundHoverSidebar
          ${selectedItem === index && !selectedSubItem ? "bg-backgroundHoverSidebar" : ""}`}
      >
        <div className="flex items-center">
          <span className="mr-2 text-lg text-white">{item.icon}</span>
          <span className="font-medium text-sm text-white">{item.label}</span>
        </div>
        {item.subItems && (
          <span>
            {activeIndex === index ? (
              <IoChevronUp className="text-gray-600" />
            ) : (
              <IoChevronDown className="text-gray-600" />
            )}
          </span>
        )}
      </button>
      {item.subItems && activeIndex === index && (
        <div className="pl-7">
          {item.subItems.map((subItem) => (
            <SidebarSubItem
              key={subItem.id}
              subItem={subItem}
              isSelected={selectedSubItem === subItem.label}
              onSelect={onSubItemClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SidebarItem;
