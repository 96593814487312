import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  IoClipboardSharp,
  IoPersonCircleSharp,
  IoLogOutSharp,
  IoSettingsSharp,
  IoReceiptSharp,
} from "react-icons/io5";
import SidebarItem from "./SidebarItem";
import { logout } from "../../../../features/auth/authSlice";
import { useRoles } from "../../../../app/hooks/useRoles";
import LogoSVG from "../../../assets/svg/LogoSVG";

interface SidebarItem {
  id: number;
  label: string;
  icon: JSX.Element;
  to?: string;
  subItems?: { id: number; label: string; to: string }[];
}

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const sidebarItemsAppCreatorSuperAdmin: SidebarItem[] = [
  {
    id: 1,
    label: "Asignaciones",
    icon: <IoClipboardSharp size={18} />,
    subItems: [
      { id: 1.1, label: "Tablero", to: "/dashboard/assignments/table-board" },
      {
        id: 1.2,
        label: "Lista",
        to: "/dashboard/assignments/table-assignments",
      },
    ],
  },
  {
    id: 2,
    label: "Facturas",
    icon: <IoReceiptSharp size={18} />,
    subItems: [
      { id: 2.1, label: "Tablero", to: "/dashboard/invoices/invoices-board" },
      { id: 2.2, label: "Lista", to: "/dashboard/invoices/table-invoices" },
    ],
  },
  {
    id: 3,
    label: "Usuarios",
    icon: <IoPersonCircleSharp size={18} />,
    to: "/dashboard/users/table-users",
  },
  {
    id: 4,
    label: "Ajustes",
    icon: <IoSettingsSharp size={18} />,
    to: "/dashboard/settings",
  },
];

const sidebarItemsAdmin: SidebarItem[] = [
  {
    id: 1,
    label: "Asignaciones",
    icon: <IoClipboardSharp size={18} />,
    subItems: [
      { id: 1.1, label: "Tablero", to: "/dashboard/assignments/table-board" },
      {
        id: 1.2,
        label: "Lista",
        to: "/dashboard/assignments/table-assignments",
      },
    ],
  },
  {
    id: 2,
    label: "Facturas",
    icon: <IoReceiptSharp size={18} />,
    subItems: [
      { id: 2.1, label: "Tablero", to: "/dashboard/invoices/invoices-board" },
      { id: 2.2, label: "Lista", to: "/dashboard/invoices/table-invoices" },
    ],
  },
  {
    id: 3,
    label: "Usuarios",
    icon: <IoPersonCircleSharp size={18} />,
    to: "/dashboard/users/table-users",
  },
];

const sidebarItemsUser: SidebarItem[] = [
  {
    id: 1,
    label: "Asignaciones",
    icon: <IoClipboardSharp size={18} />,
    subItems: [
      { id: 1.1, label: "Tablero", to: "/dashboard/assignments/table-board" },
      {
        id: 1.2,
        label: "Crear asignacion",
        to: "/dashboard/assignments/table-assignments/create-assignment",
      },
    ],
  },
  {
    id: 2,
    label: "Facturas",
    icon: <IoReceiptSharp size={18} />,
    to: "/dashboard/invoices/invoices-board",
  },
];

const Sidebar = ({ isOpen, onClose }: SidebarProps) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [selectedSubItem, setSelectedSubItem] = useState<string | null>(null);

  const { getRoleIdsByName } = useRoles();

  const roleIdsForAppCreatorAndSuperAdmin = getRoleIdsByName([
    "AppCreator",
    "SuperAdmin",
  ]);
  const roleIdsForAdmin = getRoleIdsByName(["Admin"]);
  const roleIdsForUser = getRoleIdsByName(["User"]);

  let sidebarItems: SidebarItem[] = [];
  if (roleIdsForAppCreatorAndSuperAdmin.length > 0) {
    sidebarItems = sidebarItemsAppCreatorSuperAdmin;
  } else if (roleIdsForAdmin.length > 0) {
    sidebarItems = sidebarItemsAdmin;
  } else if (roleIdsForUser.length > 0) {
    sidebarItems = sidebarItemsUser;
  }

  useEffect(() => {
    const storedActiveIndex = localStorage.getItem("activeIndex");
    const storedSelectedItem = localStorage.getItem("selectedItem");
    const storedSelectedSubItem = localStorage.getItem("selectedSubItem");

    if (storedActiveIndex !== null) {
      setActiveIndex(Number(storedActiveIndex));
    }
    if (storedSelectedItem !== null) {
      setSelectedItem(Number(storedSelectedItem));
    }
    if (storedSelectedSubItem !== null) {
      setSelectedSubItem(storedSelectedSubItem);
    }
  }, []);

  const toggleAccordion = (index: number) => {
    const newActiveIndex = activeIndex === index ? null : index;
    setActiveIndex(newActiveIndex);
    setSelectedItem(index);
    setSelectedSubItem(null);

    localStorage.setItem(
      "activeIndex",
      newActiveIndex !== null ? newActiveIndex.toString() : ""
    );
    localStorage.setItem("selectedItem", index.toString());
    localStorage.removeItem("selectedSubItem");
  };

  const handleItemClick = (to?: string) => {
    if (to) {
      navigate(to);
      onClose();
    }
  };

  const selectSubItem = (subItem: { label: string; to: string }) => {
    setSelectedSubItem(subItem.label);
    handleItemClick(subItem.to);

    localStorage.setItem("selectedSubItem", subItem.label);
    localStorage.setItem(
      "activeIndex",
      activeIndex !== null ? activeIndex.toString() : ""
    );
  };

  const handleLogout = () => {
    logout();
    window.location.href = "/auth/login";
  };

  return (
    <aside
      className={`fixed top-0 left-0 z-40 w-64 px-[24px] pb-[24px] pt-[84px] lg:pt-[24px] lg:py-[24px] h-full transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } sm:translate-x-0 bg-backgroundSidebar shadow-sm`}
    >
      <div className="flex flex-col h-full justify-between overflow-y-auto">
        <div>
          <div className="flex items-center justify-center">
            <LogoSVG className="w-[80px] h-auto" />
          </div>
          <div className="mt-[34px]">
            {sidebarItems.map((item, index) => (
              <SidebarItem
                key={item.id}
                item={item}
                index={index}
                activeIndex={activeIndex}
                selectedItem={selectedItem}
                selectedSubItem={selectedSubItem}
                onToggle={toggleAccordion}
                onItemClick={handleItemClick}
                onSubItemClick={selectSubItem}
              />
            ))}
          </div>
        </div>
        <div className="border-t border-gray-300 mt-4 pt-4">
          <button
            onClick={handleLogout}
            className="flex items-center w-full text-left p-2 rounded-lg hover:bg-backgroundHoverSidebar"
          >
            <IoLogOutSharp size={18} className="mr-2 text-white" />
            <span className="font-medium text-sm text-white">
              Cerrar sesión
            </span>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
