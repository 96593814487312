import React from 'react';
import LogoUser from '../LogoUser';
import Typography from '../Typography';
import { logout } from '../../../../features/auth/authSlice';
import Button from '../Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { UserDetails } from '../../../../features/user/types';

interface FilterModalProps {
  name: string;
  lastName: string;
}

const ProfileModal: React.FC<FilterModalProps> = ({ name, lastName }) => {
  const { email } = useSelector(
    (state: RootState) => state.user?.userDetails as UserDetails
  );

  const handleLogout = () => {
    logout();
    window.location.href = '/auth/login';
  };

  return (
    <div className="absolute mr-6 right-0 my-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
      <div className="flex flex-col items-center justify-center p-4">
        <LogoUser name={name || ''} lastName={lastName || ''} size={60} />
        <Typography
          as="h3"
          fontWeight="bold"
          fontSize="text-base"
          className="mt-5"
        >{`${name} ${lastName}`}</Typography>
        <Typography as="h3" fontWeight="normal" fontSize="text-sm">
          {email}
        </Typography>
        <div className="mt-6">
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={handleLogout}
          >
            Cerrar sesión<nav></nav>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
