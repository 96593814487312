interface IconLowestSVGProps {
  className?: string;
  width?: number;
}

const IconLowestSVG: React.FC<IconLowestSVGProps> = ({ className, width }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5049 8.14542C13.0049 7.84542 13.6049 8.04542 13.9049 8.54542C14.2049 9.04542 14.0049 9.54542 13.5049 9.84542L8.50488 12.8454C8.20488 13.0454 7.80488 13.0454 7.50488 12.8454L2.50488 9.84542C2.00488 9.54542 1.90488 8.94542 2.20488 8.44542C2.40488 8.04542 3.00488 7.84542 3.50488 8.14542L8.00488 10.8454L12.5049 8.14542Z"
        fill="#0065FF"
      />
      <path
        d="M12.5049 3.84542C13.0049 3.54542 13.6049 3.64542 13.9049 4.14542C14.2049 4.64542 14.0049 5.24542 13.5049 5.54542L8.50488 8.54542C8.20488 8.74542 7.80488 8.74542 7.50488 8.54542L2.50488 5.54542C2.00488 5.24542 1.90488 4.64542 2.20488 4.14542C2.50488 3.64542 3.10488 3.54542 3.60488 3.84542L8.00488 6.54542L12.5049 3.84542Z"
        fill="#2684FF"
      />
    </svg>
  );
};

export default IconLowestSVG;
