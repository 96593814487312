import React from 'react';
import Typography from '../../../../../../../_emailApp/layout/components/Typography';

type TaskColumnProps = {
  titleColumn: React.ReactNode;
  children: React.ReactNode;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
};

const InvoiceColumn: React.FC<TaskColumnProps> = ({
  titleColumn,
  children,
  onDragOver,
  onDrop,
}) => {
  return (
    <div
      onDragOver={onDragOver}
      onDrop={onDrop}
      className="flex-1 min-w-[280px] bg-gray-50 p-4 rounded-lg border border-gray-200"
    >
      <Typography
        as="h2"
        fontSize="text-sm"
        fontWeight="600"
        color="text-label-primary"
        className="mb-8 uppercase"
      >
        {titleColumn}
      </Typography>
      {children}
    </div>
  );
};

export default InvoiceColumn;
