/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IoCalendarOutline } from "react-icons/io5";
import ProgressBar from "./ProgressBar";
import IconDepartmentSVG from "../../../../../../_emailApp/assets/svg/IconDepartmentSVG";
import IconLowestSVG from "../../../../../../_emailApp/assets/svg/IconLowestSVG";
import Typography from "../../../../../../_emailApp/layout/components/Typography";
import LogoUser from "../../../../../../_emailApp/layout/components/LogoUser";
import IconCheckmarkSVG from "../../../../../../_emailApp/assets/svg/IconCheckmarkSVG";
import { formatDate } from "../../../../../utils/transformDate";
import { PRIORITY_LEVEL } from "../../../../../constanst/constants";
import IconHighestSVG from "../../../../../../_emailApp/assets/svg/IconHighestSVG";
import IconMediumSVG from "../../../../../../_emailApp/assets/svg/IconMediumSVG";
import { Assignments } from "../../../../../../features/assignments/types";
import clsx from "clsx";
import { truncateText } from "../../../../../utils/truncateText";

const TaskCard: React.FC<Assignments> = ({
  assignationId,
  assignationCode,
  dueDateAt,
  subject,
  title,
  priorityLevel,
  assignedTo,
  processStage,
  onDragStart,
  onClick,
  manually,
  status,
}) => {
  const getPriorityIcon = (priorityLevel: string) => {
    switch (priorityLevel) {
      case PRIORITY_LEVEL.ALTA:
        return <IconHighestSVG width={22} />;
      case PRIORITY_LEVEL.MEDIA:
        return <IconMediumSVG width={22} />;
      case PRIORITY_LEVEL.BAJA:
        return <IconLowestSVG width={22} />;
      default:
        return null;
    }
  };

  const getClassByState = (state: string): string => {
    const numericState = Number(state);

    if (numericState < 0) {
      return "bg-danger-200 text-danger";
    } else if (numericState > 5) {
      return "bg-info-200 text-info";
    } else if (numericState >= 1 && numericState <= 5) {
      return "bg-warning-200 text-warning";
    } else {
      return "bg-success-200 text-success";
    }
  };

  return (
    <div
      id={assignationId}
      draggable
      onDragStart={onDragStart}
      className={clsx("shadow p-4 mb-5 cursor-pointer rounded-lg bg-white", {
        "border-2 border-success-500": manually,
        "border-2 border-white": !manually,
      })}
      onClick={onClick}
    >
      <div className="flex items-center">
        <IconDepartmentSVG className="w-4" />
        <Typography
          as="h2"
          fontWeight="bold"
          textAlign="left"
          fontSize="text-sm"
          className="ml-3"
        >
          {assignationCode?.toUpperCase()}
        </Typography>
        <div
          className={clsx(
            "rounded-full px-2 ml-2",
            getClassByState(status || ""),
            "font-normal",
            "text-xs"
          )}
        >
          {`${status} ${status === "1" ? "día" : "días"}`}
        </div>
      </div>
      <div>
        <Typography
          as="h3"
          fontWeight="bold"
          textAlign="left"
          fontSize="text-base"
          className="mt-3"
        >
          {title}
        </Typography>
        <Typography
          as="h3"
          fontWeight="bold"
          textAlign="left"
          fontSize="text-sm"
        >
          {truncateText(subject || "", 100)}
        </Typography>
      </div>
      <ProgressBar processStage={processStage || "TODO"} />
      <div className="flex flex-row justify-between">
        <div className="flex items-center justify-center">
          <div className="flex justify-center items-center bg-background border border-solid border-label-placeholder rounded-full py-1 px-3">
            <IoCalendarOutline
              className="text-gray-400 text-lg mr-2"
              size={20}
            />
            <Typography as="h3" fontSize="text-xs" textAlign="center">
              {formatDate(dueDateAt || "")}
            </Typography>
          </div>
        </div>
        <div className="flex items-center">
          {processStage === "DONE" && (
            <IconCheckmarkSVG width={20} className="mr-2" />
          )}
          <div className="mr-2">{getPriorityIcon(priorityLevel || "")}</div>
          {typeof assignedTo === "object" && assignedTo !== null ? (
            <LogoUser
              name={assignedTo.name || ""}
              lastName={assignedTo.lastName || ""}
              size={40}
            />
          ) : (
            <LogoUser name="" lastName="" size={40} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
