import React, { FC, ReactNode } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  maxWidth?: string;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  maxWidth = "max-w-3xl",
}) => {
  if (!isOpen) return null;

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleOutsideClick}
    >
      <div
        className={`bg-white sm:p-10 p-4 rounded-lg shadow-lg ${maxWidth} w-full relative animate-fade-in`}
      >
        <button
          onClick={onClose}
          className="absolute top-0 right-0 sm:mt-2 mt-4 mr-4 text-label-primary text-xl"
          aria-label="Close modal"
        >
          &times;
        </button>

        <div className="mt-4">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
