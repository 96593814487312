import React from "react";
import Modal from "../../../../../../../_emailApp/layout/components/Modal";
import { formatCurrency } from "../../../../../../utils/formatCurrency";

interface InvoiceDetail {
  item: string;
  total: string;
  quantity: string;
  price: string;
}

interface InvoiceDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  details: InvoiceDetail[];
}

const InvoiceDetailsModal: React.FC<InvoiceDetailsModalProps> = ({
  isOpen,
  onClose,
  details,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth="max-w-4xl">
      <div className="p-4">
        <h2 className="text-lg font-bold mb-4">Detalles de la Factura</h2>
        <div className="overflow-y-auto max-h-96">
          <table className="min-w-full divide-y divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left">Item</th>
                <th className="px-6 py-3 text-left">Cantidad</th>
                <th className="px-6 py-3 text-left">Precio</th>
                <th className="px-6 py-3 text-left">Total</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {details.map((detail, index) => (
                <tr key={index}>
                  <td className="px-6 py-3">{detail.item}</td>
                  <td className="px-6 py-3">{detail.quantity}</td>
                  <td className="px-6 py-3">
                    {formatCurrency(Number(detail.price))}
                  </td>
                  <td className="px-6 py-3">
                    {formatCurrency(Number(detail.total))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default InvoiceDetailsModal;
