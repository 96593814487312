import React from 'react';
import Button from '../../../../../../_emailApp/layout/components/Button';
import { IoAddOutline } from 'react-icons/io5';

interface InvoiceHeaderProps {
  onAddInvoice: () => void;
}

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({ onAddInvoice }) => {
  return (
    <div className="mb-8">
      <div className="flex justify-end">
        <Button
          onClick={onAddInvoice}
          type="submit"
          className="ml-0 md:ml-6"
          color="primary"
          size="medium"
          width={180}
          startIcon={<IoAddOutline size="1.5em" />}
        >
          Nueva Factura
        </Button>
      </div>
    </div>
  );
};

export default InvoiceHeader;
