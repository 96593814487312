import React from 'react';

interface UserLogo {
  name: string;
  lastName: string;
  email?: string;
  withLabel?: boolean;
  size?: number;
}

const teamsColors = [
  '#498205',
  '#4777c4',
  '#6a2c8c',
  '#d24726',
  '#008272',
  '#8d8b8b',
  '#5256a1',
  '#0078d4',
  '#881798',
  '#107c10',
  '#7fba00',
  '#69797e',
  '#486860',
  '#847545',
  '#107c10',
  '#e81123',
  '#ffb900',
  '#0063b1',
  '#2d7d9a',
  '#5d5a58',
  '#498205',
  '#4777c4',
  '#6a2c8c',
  '#d24726',
  '#008272',
];

const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash &= hash;
  }
  return hash;
};

const getColorFromHash = (name: string) => {
  return teamsColors[Math.abs(hashCode(name)) % teamsColors.length];
};

const LogoUser: React.FC<UserLogo> = ({
  name,
  lastName,
  email,
  withLabel = false,
  size = 40,
}) => {
  const initials = `${name[0][0]}${lastName[0][0]}`.toUpperCase();
  const userColor = getColorFromHash(`${name[0][0]}${lastName[0][0]}`);
  const fontSize = size > 40 ? 'text-2xl' : 'text-sm';

  return (
    <div className="flex items-center">
      <div
        style={{
          backgroundColor: userColor,
          width: `${size}px`,
          height: `${size}px`,
        }}
        className={`rounded-full font-bold flex items-center justify-center text-white ${fontSize}`}
      >
        <div>{initials}</div>
      </div>
      {withLabel && (
        <div className="ml-4">
          <div className="text-base text-label-secondary font-medium">
            {name}
          </div>
          <div className="text-xs text-label-secondary">{email}</div>
        </div>
      )}
    </div>
  );
};

export default LogoUser;
