export const getCurrentISODate = (): string => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

export const getFutureISODate = (days: number) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + days);
  return currentDate.toISOString();
};
