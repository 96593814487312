import React from "react";
import { ErrorMessage, Field, useField } from "formik";

interface Option {
  value: string;
  label: string;
}

interface SelectFieldProps {
  options: Option[];
  name: string;
  placeholder?: string;
  className?: string;
  label?: string;
  labelSize?: string;
  requested?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  className,
  placeholder,
  options,
  name,
  labelSize = "text-base",
  requested,
}: SelectFieldProps) => {
  const [field] = useField(name);

  const selectClass =
    field.value === ""
      ? "mt-1 focus:shadow-soft-primary-outline text-label-placeholder text-base leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-background bg-background bg-clip-padding px-3 py-3 font-medium transition-all focus:border-primary focus:outline-none focus:transition-shadow focus:bg-primary-100"
      : "mt-1 focus:shadow-soft-primary-outline text-base leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-background bg-background bg-clip-padding px-3 py-3 font-medium text-label-secundary transition-all focus:border-primary focus:outline-none focus:transition-shadow focus:bg-primary-100";

  return (
    <div className={className}>
      <label
        className={`font-semibold ${labelSize} text-label-primary pb-1`}
        htmlFor={name}
      >
        {label}
      </label>
      {requested && (
        <label className="font-semibold text-sm text-danger pl-1">*</label>
      )}
      <Field id={name} name={name} as="select" className={selectClass}>
        {placeholder && <option value="">{placeholder}</option>}
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
      <ErrorMessage
        name={name}
        component="div"
        className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-3"
      />
    </div>
  );
};

export default SelectField;
