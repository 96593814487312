import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { fetchAPI } from "../../../../../api/fetchAPI";
import { NavbarDefault } from "../../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import TextField from "../../../../../../_emailApp/layout/components/TextField";
import Button from "../../../../../../_emailApp/layout/components/Button";
import { Department } from "../../../../../../features/departments/types";
import { setRefreshDepartments } from "../../../../../../features/departments/departmentSlice";
import { setError } from "../../../../../../features/errors/errorSlice";
import { BASE_API_URL } from "../../../../../constanst/constantURL";

const createDepartmentschema = Yup.object({
  departmentName: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("El nombre del departamento"),
  departmentDescription: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("La descripción del departamento"),
});

export const CreateDepartment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idEditDepartament = location?.state?.department?.departmentId;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const department = location?.state?.department || {
    departmentName: "",
    departmentDescription: "",
  };

  const handleSave = async (data: Department) => {
    setIsSubmitting(true);
    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/department`,
        method: "POST",
        data: data,
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "El departamento fue creado con éxito",
          })
        );
      }

      dispatch(setRefreshDepartments());
      handleNavigation();
    } catch (error) {
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdate = async (data: Department) => {
    setIsSubmitting(true);
    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/department/${idEditDepartament}`,
        method: "PUT",
        data: data,
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "El departamento fue actualizado con éxito",
          })
        );
      }

      dispatch(setRefreshDepartments());
      handleNavigation();
    } catch (error) {
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNavigation = () => {
    navigate("/dashboard/settings");
  };

  const handleTrim = (values: Department) => {
    return {
      ...values,
      departmentName: values.departmentName?.trim(),
      departmentDescription: values.departmentDescription?.trim(),
    };
  };

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault
        title={
          idEditDepartament ? "Actualizar Departamento" : "Crear Departamento"
        }
      />
      <Formik
        initialValues={department}
        validationSchema={createDepartmentschema}
        onSubmit={(values, { resetForm }) => {
          const trimmedValues = handleTrim(values);
          if (idEditDepartament) {
            handleUpdate(trimmedValues);
          } else {
            handleSave(trimmedValues);
          }
          resetForm();
        }}
      >
        {() => (
          <Form className="flex flex-col pt-3 md:pt-4 mx-4 sm:mx-24">
            <div className="mb-4">
              <TextField
                label="Nombre departamento"
                type="text"
                name="departmentName"
                placeholder="Ingresa el nombre departamento"
                requested
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Descripción departamento"
                type="text"
                name="departmentDescription"
                placeholder="Ingresa la descripción departamento"
                requested
              />
            </div>
            <div className="flex justify-center m-5 gap-6">
              <Button type="submit" color="primary" size="medium" width={200}>
                {isSubmitting ? (
                  <FaSpinner className="animate-spin" />
                ) : idEditDepartament ? (
                  "Actualizar Departamento"
                ) : (
                  "Crear Departamento"
                )}
              </Button>
              <Button
                onClick={handleNavigation}
                type="reset"
                color="cancel"
                size="medium"
                width={200}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
