import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from './types';

interface RoleState {
  roles: Role[];
}

const initialState: RoleState = {
  roles: [],
};

const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
  },
});

export const { setRoles } = roleSlice.actions;
export default roleSlice.reducer;
