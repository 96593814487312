import React from "react";
import { IoCalendarOutline } from "react-icons/io5";
import IconDepartmentSVG from "../../../../../../../_emailApp/assets/svg/IconDepartmentSVG";
import Typography from "../../../../../../../_emailApp/layout/components/Typography";
import { formatDate } from "../../../../../../utils/transformDate";
import IconCheckmarkSVG from "../../../../../../../_emailApp/assets/svg/IconCheckmarkSVG";
import LogoUser from "../../../../../../../_emailApp/layout/components/LogoUser";
import { formatCurrency } from "../../../../../../utils/formatCurrency";
import moment from "moment";
import ProgressBar from "./ProgressBar";
import clsx from "clsx";
import { STATUS_PROCESSSTAGE } from "../../../../../../constanst/constants";
import { getStatusLabelColor } from "../../../../../../utils/statusUtils";

const InvoiceCard: React.FC<any> = ({
  invoiceId,
  invoiceNumber,
  vendorName,
  details,
  totalAmount,
  invoiceDueDate,
  assignedTo,
  onDragStart,
  onClick,
  processStage,
  status,
  trackingNumber,
  project,
}) => {
  const daysDifferenceDays = invoiceDueDate
    ? moment(invoiceDueDate).diff(moment(), "days") + 1
    : 0;

  const formatDetails = (details: { item: string }[]) => {
    const combinedDetails = details?.map((detail) => detail?.item).join(", ");

    const formattedDetails =
      combinedDetails?.charAt(0).toUpperCase() +
      combinedDetails?.slice(1).toLowerCase();

    if (formattedDetails?.length > 50) {
      return formattedDetails?.slice(0, 50) + "...";
    }

    return formattedDetails;
  };

  const getClassByState = (state: number): string => {
    if (state < 0) {
      return "bg-danger-200 text-danger";
    } else if (state > 5) {
      return "bg-info-200 text-info";
    } else if (state >= 1 && state <= 5) {
      return "bg-warning-200 text-warning";
    } else {
      return "bg-success-200 text-success";
    }
  };

  return (
    <div
      id={invoiceId}
      draggable
      onDragStart={onDragStart}
      className={"shadow p-4 mb-5 cursor-pointer rounded-lg bg-white"}
      onClick={onClick}
    >
      <div className="flex items-center">
        <IconDepartmentSVG className="w-4" />
        <Typography
          as="h2"
          fontWeight="bold"
          textAlign="left"
          fontSize="text-sm"
          className="ml-3"
        >
          {trackingNumber?.toUpperCase()}
        </Typography>
      </div>
      <div className="flex">
        <div
          className={clsx(
            "rounded-full px-2 mt-1",
            getClassByState(daysDifferenceDays),
            "font-normal",
            "text-xs"
          )}
        >
          {`${daysDifferenceDays} ${daysDifferenceDays === 1 ? "día" : "días"}`}
        </div>
      </div>

      {processStage === "FINISHED" && (
        <div className={`w-full flex justify-end py-[5px]`}>
          <div className={`border border-gray-200 rounded-lg px-2 py-1`}>
            <Typography
              fontSize="text-xs"
              fontWeight="lighter"
              className={`text-${getStatusLabelColor(status)}`}
            >
              {STATUS_PROCESSSTAGE[status] || status}
            </Typography>
          </div>
        </div>
      )}
      <Typography
        as="h3"
        fontWeight="bold"
        textAlign="left"
        fontSize="text-base"
        className="mt-2"
        color="text-primary"
      >
        {vendorName}
      </Typography>
      <Typography
        as="h3"
        fontWeight="bold"
        textAlign="left"
        fontSize="text-base"
        className="mt-2 mr-1"
      >
        Proyecto: {project}
      </Typography>
      <div className="flex">
        <Typography
          as="h3"
          fontWeight="bold"
          textAlign="left"
          fontSize="text-sm"
          className="mt-2 mr-1"
        >
          No:
        </Typography>
        <Typography
          as="h3"
          fontWeight="lighter"
          textAlign="left"
          fontSize="text-sm"
          className="mt-2"
        >
          {invoiceNumber.toUpperCase()}
        </Typography>
      </div>

      <Typography
        as="h3"
        fontWeight="normal"
        textAlign="left"
        fontSize="text-sm"
      >
        {formatDetails(details)}
      </Typography>
      <div className="flex justify-end mt-[10px]">
        <Typography
          as="h3"
          fontWeight="bold"
          textAlign="left"
          fontSize="text-sm"
        >
          Total: {formatCurrency(totalAmount)}
        </Typography>
      </div>
      <ProgressBar processStage={processStage || "RECEIVED"} />
      <div className="flex flex-row justify-between">
        <div className="flex items-center justify-center">
          <div className="flex justify-center items-center bg-background border border-solid border-label-placeholder rounded-full py-1 px-3">
            <IoCalendarOutline
              className="text-gray-400 text-lg mr-2"
              size={20}
            />
            <Typography as="h3" fontSize="text-xs" textAlign="center">
              {formatDate(invoiceDueDate || "")}
            </Typography>
          </div>
        </div>
        <div className="flex items-center">
          {processStage === "FINISHED" && (
            <IconCheckmarkSVG width={20} className="mr-2" />
          )}
          {typeof assignedTo === "object" && assignedTo !== null ? (
            <LogoUser
              name={assignedTo?.name || ""}
              lastName={assignedTo?.lastName || ""}
              size={40}
            />
          ) : (
            <LogoUser name="" lastName="" size={40} />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
