interface SidebarSubItemProps {
  subItem: { id: number; label: string; to: string };
  isSelected: boolean;
  onSelect: (subItem: { label: string; to: string }) => void;
}

const SidebarSubItem = ({
  subItem,
  isSelected,
  onSelect,
}: SidebarSubItemProps) => {
  return (
    <button
      onClick={() => onSelect(subItem)}
      className={`w-full text-left p-2 hover:bg-backgroundHoverSidebar rounded-lg mb-[14px] 
        ${isSelected ? "bg-backgroundHoverSidebar" : ""}`}
    >
      <span className="text-sm text-white">{subItem.label}</span>
    </button>
  );
};

export default SidebarSubItem;
