import ItemNotifications from './ItemNotifications';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { ModalAssignments } from '../../../../app/pages/dashboard/component/board/components/ModalAssigments';
import { useState } from 'react';
import { Notification } from '../../../../features/notifications/types';

interface NotificationModalPros {
  setIsNotificationModalOpen: any;
}

const NotificationModal = ({
  setIsNotificationModalOpen,
}: NotificationModalPros) => {
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications as Notification[]
  );

  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<Notification | null>(null);

  const calculateProgressColor = (totalDays: number, daysRemaining: number) => {
    let progressPercentage = ((totalDays - daysRemaining) / totalDays) * 100;

    if (daysRemaining < 0) {
      progressPercentage = 100;
    }

    if (progressPercentage >= 75) {
      return 'bg-danger-200';
    } else if (progressPercentage >= 50) {
      return 'bg-warning-200';
    } else if (progressPercentage >= 25) {
      return 'bg-info-200';
    } else {
      return 'bg-success-200';
    }
  };

  const calculateDaysRemaining = (dispatchAt: string, dueDateAt: string) => {
    const dispatchDate = new Date(dispatchAt || '');
    const dueDate = new Date(dueDateAt || '');
    const currentDate = new Date();

    const totalDaysDifference = Math.ceil(
      (dueDate.getTime() - dispatchDate.getTime()) / (1000 * 3600 * 24)
    );
    const remainingDaysDifference = Math.ceil(
      (dueDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
    );

    return {
      totalDays: totalDaysDifference,
      daysRemaining: remainingDaysDifference,
    };
  };

  const handleNotificationClick = (task: Notification) => {
    setSelectedTask(task);
    setIsTaskModalOpen(true);
  };

  const handleTaskModalClose = () => {
    setIsTaskModalOpen(false);
    setSelectedTask(null);
    setIsNotificationModalOpen(false);
  };

  return (
    <>
      <div
        className="absolute right-0 my-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
        style={{ height: '350px' }}
      >
        <div className="flex flex-col items-center justify-center px-4">
          <div className="mt-6 overflow-y-auto" style={{ maxHeight: '300px' }}>
            {notifications?.map((item) => {
              const { totalDays, daysRemaining } = calculateDaysRemaining(
                item.dispatchAt || '',
                item.dueDateAt || ''
              );
              return (
                <ItemNotifications
                  key={item.assignationId}
                  title={item.assignationCode || ''}
                  description={item.subject || ''}
                  days={daysRemaining || 0}
                  bgColor={calculateProgressColor(totalDays, daysRemaining)}
                  onClick={() => handleNotificationClick(item)}
                />
              );
            })}
          </div>
        </div>
      </div>
      {isTaskModalOpen && selectedTask && typeof selectedTask === 'object' && (
        <ModalAssignments
          isOpen={isTaskModalOpen}
          onClose={handleTaskModalClose}
          {...selectedTask}
        />
      )}
    </>
  );
};

export default NotificationModal;
