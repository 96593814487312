interface IconCheckmarkSVGProps {
  className?: string;
  width?: number;
}

const IconCheckmarkSVG: React.FC<IconCheckmarkSVGProps> = ({
  className,
  width,
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.5 15C27.5 21.9031 21.9031 27.5 15 27.5C8.09688 27.5 2.5 21.9031 2.5 15C2.5 8.09688 8.09688 2.5 15 2.5C21.9031 2.5 27.5 8.09688 27.5 15Z"
        fill="#C8E6C9"
      />
      <path
        d="M21.6163 9.11627L13.135 17.6075L9.63377 14.1163L7.86627 15.8838L13.1375 21.1425L23.3844 10.8838L21.6163 9.11627Z"
        fill="#4CAF50"
      />
    </svg>
  );
};

export default IconCheckmarkSVG;
