import React from 'react';
import Typography from '../Typography';

interface ItemNotificationsProps {
  title: string;
  description: string;
  days: number;
  bgColor: string;
  onClick: () => void;
}

const ItemNotifications: React.FC<ItemNotificationsProps> = ({
  title,
  description,
  days,
  bgColor,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-between w-80 p-4 rounded-lg ${bgColor} text-gray-800 mb-4`}
    >
      <div className="flex items-center">
        <div>
          <Typography
            as="h3"
            fontSize="text-sm"
            textAlign="left"
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Typography
            as="h4"
            fontSize="text-sm"
            textAlign="left"
            fontWeight="400"
          >
            {description}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col text-gray-600 items-center">
        <Typography
          as="h4"
          fontSize="text-sm"
          textAlign="center"
          fontWeight="bold"
        >
          {days}
        </Typography>
        <Typography
          as="h4"
          fontSize="text-sm"
          textAlign="center"
          fontWeight="400"
        >{` dias`}</Typography>
      </div>
    </div>
  );
};

export default ItemNotifications;
