import React, { useEffect, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";

interface LoadingAnimationProps {
  isLoading: boolean;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ isLoading }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isLoading) {
      let interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 1 : 100
        );
      }, 50);

      return () => clearInterval(interval);
    } else {
      setProgress(100);
    }
  }, [isLoading]);

  return (
    <div className="flex items-center justify-center p-4 bg-gray-50 rounded-lg shadow-md w-[500px]">
      <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
        <IoCloudUploadOutline
          size={30}
          className="text-[#4B409A] animate-pulse"
        />
      </div>
      <div className="flex-1 px-4">
        <div className="flex items-center space-x-2">
          <span className="text-lg font-semibold">{progress}%</span>
          <span className="text-sm text-gray-500">Subiendo...</span>
        </div>
        <div className="relative h-2 mt-2 rounded-full overflow-hidden bg-gray-300">
          <div
            className="absolute top-0 bottom-0 left-0 bg-gradient-to-r from-[#4B409A] to-[#F55D38]"
            style={{
              width: `${progress}%`,
              transition: "width 0.2s ease-in-out",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
