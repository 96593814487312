import React from "react";
import Button from "../../../../../../_emailApp/layout/components/Button";
import { IoAddOutline } from "react-icons/io5";
import Typography from "../../../../../../_emailApp/layout/components/Typography";

interface UserHeaderProps {
  onAddDepartment: () => void;
}

const UserDepartmentSettings: React.FC<UserHeaderProps> = ({
  onAddDepartment,
}) => {
  return (
    <div className="mb-8">
      <div className="flex justify-between text-secondary mb-4 relative flex-col md:flex-row">
        <div className="lg:ml-10 mb-[10px] lg:mb-0">
          <Typography
            textAlign="left"
            as="h3"
            fontWeight="bold"
            fontSize="text-base"
          >
            Departamentos
          </Typography>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center">
          <Button
            onClick={onAddDepartment}
            type="submit"
            className="ml-0 md:ml-6"
            color="primary"
            size="medium"
            width={220}
            startIcon={<IoAddOutline size="1.5em" />}
          >
            Nuevo Departamento
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserDepartmentSettings;
