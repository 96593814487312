import React from "react";
import { ErrorMessage, useField } from "formik";
import Select from "react-select";
import { SingleValue } from "react-select";
import "tailwindcss/tailwind.css";

interface Option {
  value: string;
  label: string;
}

interface FilteredSelectProps {
  options: Option[];
  name: string;
  placeholder?: string;
  className?: string;
  label?: string;
  requested?: boolean;
}

const FilteredSelect: React.FC<FilteredSelectProps> = ({
  label,
  className,
  placeholder,
  options,
  name,
  requested,
}: FilteredSelectProps) => {
  const [field, , helpers] = useField(name);

  const handleChange = (option: SingleValue<Option>) => {
    helpers.setValue(option?.value || "");
  };

  return (
    <div className={className}>
      <label
        className="font-semibold text-base text-label-primary pb-1"
        htmlFor={name}
      >
        {label}
      </label>
      {requested && (
        <label className="font-semibold text-sm text-danger pl-1">*</label>
      )}
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "#F6F7F9",
            padding: "5px",
            border: "none",
            borderRadius: "10px",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#BCBCBC",
            fontSize: "16px",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#1F1F22",
          }),
        }}
        id={name}
        name={name}
        options={options}
        value={options.find((option) => option.value === field.value)}
        onChange={handleChange}
        placeholder={placeholder}
        classNamePrefix="react-select"
        isClearable
      />
      <ErrorMessage
        name={name}
        component="div"
        className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-3"
      />
    </div>
  );
};

export default FilteredSelect;
