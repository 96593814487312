import { Link, useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Typography from "../../../../_emailApp/layout/components/Typography";
import TextField from "../../../../_emailApp/layout/components/TextField";
import Button from "../../../../_emailApp/layout/components/Button";
import { useTranslation } from "react-i18next";
import { BASE_API_URL } from "../../../constanst/constantURL";
import { fetchAPI } from "../../../api/fetchAPI";
import { setError } from "../../../../features/errors/errorSlice";
import { useDispatch } from "react-redux";

interface ForgotPasswordFormValues {
  email: string;
}

const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email("Formato de email inválido")
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("El email es obligatorio"),
});

export function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (
    values: ForgotPasswordFormValues,
    actions: FormikHelpers<ForgotPasswordFormValues>
  ) => {
    const url = `${BASE_API_URL}/public/auth/requestPasswordResetCode/${values.email}`;
    try {
      const response = await fetchAPI({
        url: url,
        method: "POST",
      });

      if (response.status === 200 || response.status === 204) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage:
              "El correo de restablecimiento de contraseña fue enviado",
          })
        );
      }
      navigateToVerifyCode(values.email);
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage:
            "Hubo un problema al enviar el correo de restablecimiento",
        })
      );
    } finally {
      actions.setSubmitting(false);
    }
  };

  const navigateToVerifyCode = (email: string) => {
    navigate("/auth/verify-code", { state: { email } });
  };

  return (
    <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
      <Typography
        as="h1"
        fontSize="text-base"
        textAlign="center"
        fontWeight="500"
      >
        ¿Olvidaste tu contraseña?
      </Typography>
      <Typography
        as="h2"
        fontSize="text-sm"
        textAlign="center"
        fontWeight="400"
        className="mt-6 mb-10"
      >
        Ingrese el correo electrónico utilizado cuando creó su cuenta
      </Typography>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={forgotPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <div className="mb-4">
              <TextField
                label="Correo Electrónico"
                type="email"
                name="email"
                placeholder="Ingresa tu correo electrónico"
              />
            </div>
            <div className="pt-5 flex justify-end">
              <Typography
                as="span"
                fontSize="text-sm"
                fontWeight="700"
                textAlign="right"
              >
                <Link to={"/auth/login"}>{t("auth.RETURN_TO_LOG")}</Link>
              </Typography>
            </div>
            <div className="flex justify-center mt-12">
              <Button
                type="submit"
                disabled={!isValid}
                color="primary"
                size="medium"
                width={300}
              >
                Restablecer la contraseña
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
