import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Assignments } from "./types";

interface AssignmentState {
  assignments: Assignments | null;
  refreshAssignments: boolean;
  fileUpload: File | null;
  typeAssignment: boolean;
}

const initialState: AssignmentState = {
  assignments: null,
  refreshAssignments: false,
  fileUpload: null,
  typeAssignment: false,
};

const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    setAssignments: (state, action: PayloadAction<Assignments>) => {
      state.assignments = action.payload;
    },
    setFileUpload: (state, action: PayloadAction<File | null>) => {
      state.fileUpload = action.payload;
    },
    setRefreshAssignments(state) {
      state.refreshAssignments = !state.refreshAssignments;
    },
    setTypeAssignment(state, action) {
      state.typeAssignment = action.payload;
    },
  },
});

export const {
  setAssignments,
  setRefreshAssignments,
  setFileUpload,
  setTypeAssignment,
} = assignmentSlice.actions;
export default assignmentSlice.reducer;
