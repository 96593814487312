import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onSelectPage: (pageNumber: number) => void;
}

const Pagination = ({
  totalPages,
  currentPage,
  onSelectPage,
}: PaginationProps) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <nav>
      <ul className="flex mb-10 mt-5">
        <li>
          <button
            className="cursor-pointer mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 p-0 text-xs text-blue-gray-500 transition duration-150 ease-in-out hover:bg-primary-300"
            aria-label="Previous"
            onClick={() => onSelectPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <FiChevronLeft />
          </button>
        </li>
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber}>
            <button
              className={`cursor-pointer mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 p-0 text-xs text-blue-gray-500 transition duration-150 ease-in-out hover:bg-primary-300 ${pageNumber === currentPage ? 'bg-primary-500 text-white shadow-md' : ''}`}
              onClick={() => onSelectPage(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        <li>
          <button
            className="cursor-pointer mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 p-0 text-xs text-blue-gray-500 transition duration-150 ease-in-out hover:bg-primary-300"
            aria-label="Next"
            onClick={() => onSelectPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <FiChevronRight />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
