import React, { useState, useEffect } from "react";
import { pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import PaginationPDF from "../../assignments/components/PaginationPDF";
import { fetchAPI } from "../../../../../api/fetchAPI";
import CreateInvoice from "./CreateInvoice";
import { useDispatch } from "react-redux";
import { setError } from "../../../../../../features/errors/errorSlice";
import LoadingAnimation from "../../../../../../_emailApp/layout/components/LoadingAnimation";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../../../constanst/constantURL";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFPage {
  getViewport: (params: { scale: number }) => { width: number; height: number };
  render: (params: {
    canvasContext: CanvasRenderingContext2D;
    viewport: any;
  }) => { promise: Promise<void> };
}

interface PDFDocumentProxy {
  numPages: number;
  getPage: (pageNumber: number) => Promise<PDFPage>;
}

const InvocesPDFViewer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileUpload = useSelector(
    (state: RootState) => state.assignments.fileUpload as File | null
  );

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [analyzeComplete, setAnalyzeComplete] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);

  const fileType = fileUpload?.type || "";

  const handleCreateInvoice = async (): Promise<void> => {
    setAnalyzeComplete(false);
    if (!fileUpload) {
      dispatch(
        setError({
          errorCode: 400,
          errorMessage: "No se ha seleccionado ningún archivo para subir.",
        })
      );
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", fileUpload);

    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/expense/analyze`,
        method: "POST",
        data: formData,
      });

      dispatch(
        setError({
          errorCode: 200,
          errorMessage: "El archivo fue cargado y analizado con éxito.",
        })
      );

      setTableData(response.data);
      setAnalyzeComplete(true);
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage: "Error al analizar el archivo. Inténtalo de nuevo.",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const loadPDF = async (): Promise<void> => {
    setLoading(true);

    if (!fileUpload) {
      setLoading(false);
      navigate("/dashboard/invoices/table-invoices");
      return;
    }

    try {
      const fileUrl = URL.createObjectURL(fileUpload!);
      const pdf: PDFDocumentProxy = await pdfjs.getDocument(fileUrl).promise;
      const totalPages = pdf.numPages;
      setNumPages(totalPages);

      const imagePromises = Array.from({ length: totalPages }, (_, index) =>
        pdfToImage(pdf, index + 1)
      );

      const imageData = await Promise.all(imagePromises);
      setImages(imageData);
      await handleCreateInvoice();
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const pdfToImage = async (
    pdf: PDFDocumentProxy,
    pageNumber: number
  ): Promise<string> => {
    const page = await pdf.getPage(pageNumber);
    const originalViewport = page.getViewport({ scale: 1.0 });
    const scaleWidth = 3228 / originalViewport.width;
    const scaleHeight = 4242 / originalViewport.height;
    const scale = Math.min(scaleWidth, scaleHeight);

    const viewport = page.getViewport({ scale });

    const canvas = document.createElement("canvas");
    canvas.width = 3228;
    canvas.height = 4242;
    const context = canvas.getContext("2d");

    if (!context) throw new Error("Canvas context not available");

    await page.render({
      canvasContext: context,
      viewport: { ...viewport, width: 3228, height: 4242 },
    }).promise;
    return canvas.toDataURL("image/png");
  };

  const goToPreviousPage = (): void => {
    setPageNumber((previousPageNumber) => Math.max(1, previousPageNumber - 1));
  };

  const goToNextPage = (): void => {
    setPageNumber((previousPageNumber) =>
      Math.min(previousPageNumber + 1, numPages || 1)
    );
  };

  const refresh = (): void => {
    setPageNumber(() => Math.min(1));
  };

  const loadImageAndAnalyze = (): void => {
    setLoading(true);

    if (!fileUpload) {
      dispatch(
        setError({
          errorCode: 400,
          errorMessage: "No se ha seleccionado ningún archivo para analizar.",
        })
      );
      setLoading(false);
      return;
    }

    const imageUrl = URL.createObjectURL(fileUpload!);
    setImages([imageUrl]);
    handleCreateInvoice();
  };

  useEffect(() => {
    if (!fileUpload) return;

    if (fileType.includes("pdf")) {
      loadPDF();
    } else if (fileType.includes("image")) {
      loadImageAndAnalyze();
    }
  }, [fileUpload]);

  const imageUrl = images[0];

  return (
    <div className="sm:ml-64">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <LoadingAnimation isLoading={loading} />
        </div>
      ) : analyzeComplete ? (
        <div className="p-4 mt-14 flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2 overflow-auto h-full">
            {tableData && (
              <CreateInvoice tableData={tableData} fileUpload={fileUpload} />
            )}
          </div>
          <div className="w-full lg:w-1/2 bg-gray-100 flex flex-col h-full">
            <div className="flex-1 py-[30px] px-[70px] flex justify-center items-center">
              {fileType.includes("pdf") && images[pageNumber - 1] && (
                <img
                  src={images[pageNumber - 1]}
                  alt={`Page ${pageNumber}`}
                  className="object-contain w-full h-full"
                />
              )}
              {fileType.includes("image") && imageUrl && (
                <img
                  src={imageUrl}
                  alt="Uploaded Image"
                  className="object-contain w-full h-full"
                />
              )}
            </div>
            {fileType.includes("pdf") && (
              <div className="flex justify-center items-center">
                <div className="fixed bottom-[15px] flex justify-center items-center">
                  <PaginationPDF
                    currentPage={pageNumber}
                    totalPages={numPages || 0}
                    onPrevious={goToPreviousPage}
                    onNext={goToNextPage}
                    onRefresh={refresh}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <p>No se pudo cargar el archivo.</p>
        </div>
      )}
    </div>
  );
};

export default InvocesPDFViewer;
