import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LogoUser from "../../../../../../_emailApp/layout/components/LogoUser";
import { NavbarDefault } from "../../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import NoResultFound from "../../../../../../_emailApp/layout/components/NoResultFound";
import { UserDetails } from "../../../../../../features/user/types";
import { RootState } from "../../../../../store";
import { fetchBoard, fetchAllUsers } from "./service/apiService";
import InvoiceBoard from "./InvoiceBoard";
import { useRoles } from "../../../../../hooks/useRoles";

const TableBoard: React.FC = () => {
  const refreshInvoices = useSelector(
    (state: RootState) => state.invoice.refreshInvoices
  );
  const { userId } = useSelector(
    (state: RootState) => state.user?.userDetails as UserDetails
  );

  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [userIdSelected, setUserIdSelected] = useState<string>(
    userId as string
  );
  const [noInvoices, setNoInvoices] = useState<boolean>(false);

  const { getCurrentUserRole } = useRoles();
  const currentUserRole = getCurrentUserRole();
  const role = currentUserRole?.roleName;

  const fetchInvoices = useCallback(async (userId?: string) => {
    try {
      const isUserRole = role === "User";
      const data = await fetchBoard(userId, isUserRole);
      if (data.length === 0) {
        setNoInvoices(true);
      } else {
        setNoInvoices(false);
      }
    } catch (error) {
      setNoInvoices(true);
    }
  }, []);

  const loadAllUsers = useCallback(async () => {
    try {
      const data = await fetchAllUsers();
      const reorderedUsers = data.sort((a: any, b: any) =>
        a.userId === userId ? -1 : 1
      );
      setAllUsers(reorderedUsers);
    } catch (error) {
      return;
    }
  }, [userId]);

  useEffect(() => {
    if (role !== "User") {
      loadAllUsers();
    }
    fetchInvoices(userIdSelected);
  }, [refreshInvoices, fetchInvoices, userIdSelected, loadAllUsers]);

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault title="Tablero" />
      <div className="flex flex-row mb-10 ml-8">
        {role !== "User" &&
          allUsers?.map((item: any) => (
            <div
              key={item.userId}
              className="flex flex-col items-center relative group"
            >
              <button
                onClick={() => setUserIdSelected(item.userId)}
                className={`ml-[-15px] transition-transform duration-300 ${userIdSelected === item.userId ? "scale-110 bg-blue-100" : ""} rounded-full p-1 hover:bg-blue-200 relative`}
              >
                <LogoUser
                  name={item.name || ""}
                  lastName={item.lastName || ""}
                  size={40}
                />
              </button>
              <div className="absolute top-full mt-1 w-full text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-sm text-primary whitespace-nowrap bg-primary-100 rounded px-2 py-1">
                  {item.name} {item.lastName}
                </span>
              </div>
            </div>
          ))}
      </div>

      {noInvoices ? (
        <div>
          <NoResultFound />
        </div>
      ) : (
        <>
          <div className="mt-5 mx-5">
            <InvoiceBoard userId={userIdSelected} />
          </div>
        </>
      )}
    </div>
  );
};

export default TableBoard;
