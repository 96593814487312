import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "../features/user/userSlice";
import roleReducer from "../features/roles/roleSlice";
import departmentReducer from "../features/departments/departmentSlice";
import assignmentReducer from "../features/assignments/assignmentSlice";
import authReducer from "../features/auth/authSlice";
import notificationReducer from "../features/notifications/notificationsSlice";
import tabReducer from "../features/tabs/tabSlice";
import pdfViewerReducer from "../features/invoices/pdfViewerSlice";
import invoiceReducer from "../features/invoices/invoiceSlice";
import errorReducer from "../features/errors/errorSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user", "roles", "departments", "notifications"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  roles: roleReducer,
  departments: departmentReducer,
  assignments: assignmentReducer,
  notifications: notificationReducer,
  tab: tabReducer,
  pdfViewer: pdfViewerReducer,
  invoice: invoiceReducer,
  error: errorReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/REGISTER",
          "assignment/setFileUpload",
        ],
        ignoredPaths: ["assignments.fileUpload"],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
