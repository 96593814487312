import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../../_emailApp/layout/components/navbar/Navbar";
import Sidebar from "../../../_emailApp/layout/components/Sidebar/Sidebar";

const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="relative min-h-screen">
      <main className="relative z-1">
        <Outlet />
      </main>
      <div className="relative z-0">
        <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} />
        <Navbar toggleSidebar={toggleSidebar} />
      </div>
    </div>
  );
};

export { DashboardLayout };
