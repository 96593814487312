import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PdfViewerState {
  fileUpload: File | null;
  numPages: number;
  refreshInvoices: boolean;
}

const initialState: PdfViewerState = {
  fileUpload: null,
  numPages: 0,
  refreshInvoices: false,
};

const pdfViewerSlice = createSlice({
  name: 'pdfViewer',
  initialState,
  reducers: {
    setPdf(state, action: PayloadAction<File | null>) {
      state.fileUpload = action.payload;
    },
    setNumPages(state, action: PayloadAction<number>) {
      state.numPages = action.payload;
    },
    clearPdf(state) {
      state.fileUpload = null;
      state.numPages = 0;
    }
  },
});

export const { setPdf, setNumPages, clearPdf } =
  pdfViewerSlice.actions;
export default pdfViewerSlice.reducer;
