import React, { ChangeEvent, DragEvent } from "react";
import { useDispatch } from "react-redux";
import { setFileUpload } from "../../../../../../features/assignments/assignmentSlice";
import { NavbarDefault } from "../../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import IconDownloadSVG from "../../../../../../_emailApp/assets/svg/IconDownloadSVG";
import { useNavigate } from "react-router-dom";

const InvoiceFile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      navigate("/dashboard/invoices/table-invoices/invoices-pdf");
      dispatch(setFileUpload(file));
    } else {
      navigate("/dashboard/invoices/table-invoices");
    }
  };

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;

    if (file) {
      dispatch(setFileUpload(file));
      navigate("/dashboard/invoices/table-invoices/invoices-pdf");
    } else {
      navigate("/dashboard/invoices/table-invoices");
    }
  };

  return (
    <div className="p-4 sm:ml-64">
      <div className="flex justify-center items-center min-h-screen flex-col">
        <NavbarDefault title={"Ingresa la factura"} />
        <label
          htmlFor="file-upload"
          className="flex flex-col items-center justify-center p-2 sm:p-10 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-primary-100 hover:border-primary-500 transition duration-150 ease-in-out"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="flex flex-col items-center justify-center space-y-4">
            <IconDownloadSVG className="w-16 h-16 text-gray-400" />
            <p className="text-center text-sm font-semibold text-gray-700">
              Deja tu documento aquí, o
              <span className="text-blue-600 underline ml-1">navegar</span>
            </p>
            <p className="text-center text-xs text-gray-500">
              Soporta (PDF, JPG, PNG)
            </p>
          </div>
          <input
            id="file-upload"
            type="file"
            className="hidden"
            accept="application/pdf, image/jpeg, image/png"
            onChange={handleFileChange}
          />
        </label>
      </div>
    </div>
  );
};

export default InvoiceFile;
