interface IconMediumSVGProps {
  className?: string;
  width?: number;
}

const IconMediumSVG: React.FC<IconMediumSVGProps> = ({ className, width }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 0H11C11.6 0 12 0.4 12 1C12 1.6 11.6 2 11 2H1C0.4 2 0 1.6 0 1C0 0.4 0.4 0 1 0ZM1 6H11C11.6 6 12 6.4 12 7C12 7.6 11.6 8 11 8H1C0.4 8 0 7.6 0 7C0 6.4 0.4 6 1 6Z"
        fill="#FFAB00"
      />
    </svg>
  );
};

export default IconMediumSVG;
