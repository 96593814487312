import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbarDefault } from "../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import Typography from "../../../../../_emailApp/layout/components/Typography";
import SelectField from "../../../../../_emailApp/layout/components/SelectField";
import LogoUser from "../../../../../_emailApp/layout/components/LogoUser";
import RadioGroup from "../../../../../_emailApp/layout/components/RadioGroup";
import Button from "../../../../../_emailApp/layout/components/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { Department } from "../../../../../features/departments/types";
import { UserDetails } from "../../../../../features/user/types";
import { fetchAPI } from "../../../../api/fetchAPI";
import { User } from "../users/TableUser";
import {
  getCurrentISODate,
  getFutureISODate,
} from "../../../../utils/dateUtils";
import { setRefreshUsers } from "../../../../../features/user/userSlice";
import { FaSpinner } from "react-icons/fa";
import FilteredSelect from "../../../../../_emailApp/layout/components/FilteredSelect";
import { Assignments } from "../../../../../features/assignments/types";
import moment from "moment";
import TextField from "../../../../../_emailApp/layout/components/TextField";
import { setError } from "../../../../../features/errors/errorSlice";
import { BASE_API_URL } from "../../../../constanst/constantURL";
import { useRoles } from "../../../../hooks/useRoles";

interface Option {
  value: string;
  label: string;
}

interface SaveUser {
  title?: string;
  subject?: string;
  estimatedTime: string;
  departmentId: string;
  assignedTo: string;
  priorityLevel: string;
}

export const radioOptions: Option[] = [
  { value: "Alta", label: "Alta" },
  { value: "Media", label: "Media" },
  { value: "Baja", label: "Baja" },
];

const validationSchema = (typeAssignment: boolean) => {
  return Yup.object().shape({
    title: typeAssignment
      ? Yup.string()
          .required("El título de la tarea es requerido")
          .min(3, "Mínimo 3 caracteres")
          .max(100, "Máximo 100 caracteres")
      : Yup.string(),
    subject: typeAssignment
      ? Yup.string()
          .required("La descripción de la tarea es requerida")
          .min(3, "Mínimo 3 caracteres")
          .max(500, "Máximo 500 caracteres")
      : Yup.string(),
    estimatedTime: Yup.string().required("El tiempo asignado es requerido"),
    departmentId: Yup.string().required("El departamento es requerido"),
    assignedTo: Yup.string().required("La asignación es requerida"),
    priorityLevel: Yup.string().required("La prioridad es requerida"),
  });
};

export const CreateAssignments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useSelector(
    (state: RootState) => state.user.userDetails as UserDetails
  );
  const departments = useSelector(
    (state: RootState) => state.departments.departments as Department[]
  );
  const { name, lastName } = useSelector(
    (state: RootState) => state.user.userDetails as UserDetails
  );
  const dataAssignmentsPreview = useSelector(
    (state: RootState) => state.assignments.assignments as Assignments
  );
  const fileUpload = useSelector(
    (state: RootState) => state.assignments.fileUpload as File | null
  );
  const typeAssignment = useSelector(
    (state: RootState) => state.assignments.typeAssignment as boolean
  );

  const assignment = location?.state?.assignment || {
    estimatedTime: "",
    assignedTo: "",
    priorityLevel: radioOptions[0].value,
  };
  const [selectedEstimationDays, setSelectedEstimationDays] = useState<
    Option[]
  >([]);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currentISODate, setCurrentISODate] = useState<string>("");
  const isEdite = assignment.code;

  const { getCurrentUserRole } = useRoles();
  const currentUserRole = getCurrentUserRole();
  const role = currentUserRole?.roleName;

  const isUserRole = role === "User";

  console.log(isUserRole);

  console.log(typeAssignment);

  const departmentOptions = departments?.map((department) => ({
    value: department.departmentId ?? "",
    label: department.departmentName ?? "",
  }));

  const allUsersOptions = allUsers?.map((user: User) => ({
    value: user.userId ?? "",
    label: `${user.name} ${user.lastName}`,
  }));

  const generateEstimationDayOptions = (maxDay: number) => {
    const estimationDayOptions = [];
    for (let i = 1; i <= maxDay; i++) {
      const label = i === 1 ? "1 día" : `${i} días`;
      estimationDayOptions.push({ value: i?.toString(), label });
    }
    return estimationDayOptions;
  };

  const getAllUser = async () => {
    try {
      const resp = await fetchAPI({
        url: `${BASE_API_URL}/user`,
        method: "GET",
      });

      if (
        Array.isArray(resp.data) &&
        resp.data.every((item) => typeof item.userId !== "undefined")
      ) {
        setAllUsers(resp.data);
      }
    } catch (error) {
      return;
    }
  };

  const handleCreateUrl = async () => {
    if (!fileUpload) {
      return;
    }

    const formData = new FormData();
    formData.append("file", fileUpload);

    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/document/uploadDoc`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      return;
    }
  };

  const handleSave = async (data: SaveUser) => {
    setIsSubmitting(true);
    try {
      const fileUrl = await handleCreateUrl();
      const combinedObject = {
        ...data,
        ...dataAssignmentsPreview,
        dispatchBy: userId,
        dispatchAt: currentISODate,
        dueDateAt: getFutureISODate(Number(data.estimatedTime)),
        assignationFileUrl: fileUrl,
        manually: isUserRole ? true : typeAssignment,
      };
      await fetchAPI({
        url: `${BASE_API_URL}/assignation`,
        method: "POST",
        data: combinedObject,
      });

      dispatch(setRefreshUsers());
      dispatch(
        setError({
          errorCode: 200,
          errorMessage: "La asignación fue creada con éxito.",
        })
      );

      handleNavigation();
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage: "Error al crear la asignación. Inténtalo de nuevo.",
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNavigation = () => {
    navigate("/dashboard/assignments/table-board");
  };

  useEffect(() => {
    getAllUser();
    setCurrentISODate(getCurrentISODate());
    const estimationDays = generateEstimationDayOptions(15);
    setSelectedEstimationDays(estimationDays);
  }, []);

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault title={"Crear Asignación"} />
      <Formik
        initialValues={{
          title: typeAssignment ? "" : assignment.title || "",
          subject: typeAssignment ? "" : assignment.subject || "",
          estimatedTime: assignment.estimatedTime || "",
          departmentId: assignment.departmentId || "",
          assignedTo: assignment.assignedTo || "",
          priorityLevel: assignment.priorityLevel || radioOptions[0].value,
        }}
        validationSchema={validationSchema(typeAssignment)}
        onSubmit={(values, { resetForm }) => {
          handleSave(values);
          resetForm();
        }}
      >
        {({ values }) => (
          <Form className="flex flex-col pt-3 md:pt-4 mx-4 sm:mx-24">
            {!typeAssignment && (
              <div className="mb-4">
                <Typography
                  fontWeight="bolder"
                  as="h3"
                  fontSize="text-base"
                  className="pb-1"
                >
                  Asunto
                </Typography>
                <Typography
                  textAlign="left"
                  as="h3"
                  fontWeight="normal"
                  fontSize="text-base"
                >
                  {dataAssignmentsPreview?.subject}
                </Typography>
              </div>
            )}
            <div className="flex mb-4">
              <div className="flex-1">
                <Typography
                  fontWeight="bolder"
                  as="h3"
                  fontSize="text-base"
                  className="pb-1"
                >
                  Fecha Creación:
                </Typography>
                <Typography as="p" fontSize="text-base">
                  {isEdite
                    ? assignment?.shippingDate
                    : moment(getCurrentISODate()).format("DD/MM/YYYY")}
                </Typography>
              </div>
              <div className="flex-1">
                <Typography
                  fontWeight="bolder"
                  as="h3"
                  fontSize="text-base"
                  className="pb-1"
                >
                  Fecha Finalización:
                </Typography>
                <Typography as="p" fontSize="text-base">
                  {isEdite
                    ? assignment?.closeLimit
                    : moment(
                        getFutureISODate(Number(values.estimatedTime))
                      ).format("DD/MM/YYYY")}
                </Typography>
              </div>
            </div>
            {(typeAssignment || isUserRole) && (
              <>
                <div className="mb-4">
                  <TextField
                    label="Título"
                    name="title"
                    type="text"
                    placeholder="Ingresa un título de la tarea"
                    requested
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    label="Descripción"
                    name="subject"
                    type="textarea"
                    placeholder="Ingresa la descripción de la tarea"
                    requested
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <SelectField
                label="Estimación"
                name="estimatedTime"
                options={selectedEstimationDays}
                placeholder="Seleccione una opción"
                requested
              />
            </div>
            <div className="mb-4">
              <SelectField
                label="Departamento"
                placeholder="Selecciona una opción"
                name="departmentId"
                options={departmentOptions}
                requested
              />
            </div>
            <div className="mb-4">
              <FilteredSelect
                name="assignedTo"
                label="Asignar a"
                options={allUsersOptions}
                placeholder="Selecciona un usuario"
                className="my-custom-class"
                requested
              />
            </div>
            <div className="mb-4">
              <RadioGroup
                label="Prioridad"
                options={radioOptions}
                name="priorityLevel"
                requested
              />
            </div>
            <div className="mb-4">
              <Typography
                fontWeight="bolder"
                as="h3"
                fontSize="text-base"
                className="pb-1"
              >
                Informador
              </Typography>
              <div className="flex">
                <LogoUser
                  name={name || ""}
                  lastName={lastName || ""}
                  size={40}
                />
                <div className="ml-3">
                  <Typography as="h3" fontSize="text-base" fontWeight="bolder">
                    {name}
                  </Typography>
                  <Typography as="h3" fontSize="text-base">
                    {lastName}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="flex justify-center m-5 gap-6">
              <Button type="submit" color="primary" size="medium" width={200}>
                {isSubmitting ? (
                  <FaSpinner className="animate-spin" />
                ) : (
                  "Crear Asignación"
                )}
              </Button>
              <Button
                type="button"
                color="cancel"
                size="medium"
                width={200}
                onClick={handleNavigation}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
