import React, { useState, useEffect } from "react";
import { pdfjs } from "react-pdf";
import { Tesseract } from "tesseract.ts";
import LoadingSpinner from "../../../../../../_emailApp/layout/components/LoadingSpinner";
import PaginationPDF from "./PaginationPDF";
import Button from "../../../../../../_emailApp/layout/components/Button";
import { useNavigate } from "react-router-dom";
import { setAssignments } from "../../../../../../features/assignments/assignmentSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Typography from "../../../../../../_emailApp/layout/components/Typography";
import { fetchAPI } from "../../../../../api/fetchAPI";
import { setRefreshUsers } from "../../../../../../features/user/userSlice";
import { setError } from "../../../../../../features/errors/errorSlice";
import LoadingAnimation from "../../../../../../_emailApp/layout/components/LoadingAnimation";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFGalleryProps {
  pdfUrl: string;
  isResponseDocument: boolean;
  taskId: string;
}

const PDFViewer: React.FC<PDFGalleryProps> = ({
  pdfUrl,
  isResponseDocument,
  taskId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileUpload = useSelector(
    (state: RootState) => state.assignments.fileUpload as File | null
  );

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [ocrLoading, setOcrLoading] = useState<boolean>(false);
  const [ocrText, setOcrText] = useState<string[]>([]);
  const [progress, setProgress] = useState(0);
  const [extractedData, setExtractedData] = useState<any>(null);

  const loadPDF = async () => {
    try {
      const pdf = await pdfjs.getDocument(pdfUrl).promise;
      const totalPages = pdf.numPages;
      setNumPages(totalPages);

      const imagePromises = Array.from({ length: totalPages }, (_, index) =>
        pdfToImage(pdf, index + 1)
      );

      const imageData = await Promise.all(imagePromises);
      setImages(imageData);
      setOcrLoading(true);

      const ocrPromises = imageData.map((image) =>
        Tesseract.recognize(image, "spa")
          .progress((progress: any) => {
            setProgress(progress.status);
          })
          .then((result: any) => {
            if (result && result.text) {
              setOcrText((prevText) => [...prevText, result.text]);
            }
          })
          .catch(() => {
            return;
          })
          .finally(() => {
            setOcrLoading(false);
          })
      );

      await Promise.all(ocrPromises);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const pdfToImage = async (pdf: any, pageNumber: number): Promise<string> => {
    const page = await pdf.getPage(pageNumber);
    const originalViewport = page.getViewport({ scale: 1.0 });
    const scaleWidth = 3228 / originalViewport.width;
    const scaleHeight = 4242 / originalViewport.height;
    const scale = Math.min(scaleWidth, scaleHeight);

    const viewport = page.getViewport({ scale });

    const canvas = document.createElement("canvas");
    canvas.width = 3228;
    canvas.height = 4242;
    const context = canvas.getContext("2d");

    if (!context) throw new Error("Canvas context not available");

    await page.render({
      canvasContext: context,
      viewport: { ...viewport, width: 3228, height: 4242 },
    }).promise;
    return canvas.toDataURL("image/png");
  };

  const extractData = (text: string) => {
    const subjectRegex = /Asunto: .+/g;

    const subject = text.match(subjectRegex);

    return {
      subject: subject ? subject[0] : null,
    };
  };

  const goToPreviousPage = () => {
    setPageNumber((previousPageNumber) => Math.max(1, previousPageNumber - 1));
  };

  const goToNextPage = () => {
    setPageNumber((previousPageNumber) =>
      Math.min(previousPageNumber + 1, numPages || 1)
    );
  };

  const refresh = () => {
    setPageNumber(() => Math.min(1));
  };

  const handleCreateAssignment = () => {
    const assignment = {
      subject: extractedData?.subject,
    };
    dispatch(setAssignments(assignment));
    navigate("/dashboard/assignments/table-assignments/create-assignment");
  };

  useEffect(() => {
    if (ocrText.length > 0) {
      const extracted = extractData(ocrText.join(" "));
      setExtractedData(extracted);
    }
  }, [ocrText]);

  useEffect(() => {
    loadPDF();
  }, [pdfUrl]);

  return (
    <div>
      <div className="flex h-full justify-center items-center">
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <LoadingAnimation isLoading={loading} />
          </div>
        ) : (
          <div className="mt-14 flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/2 overflow-auto h-full pr-10">
              {ocrLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="mt-14">
                  <Typography
                    fontSize="text-sm"
                    fontWeight="bold"
                    className="mb-1"
                  >
                    Asunto:
                  </Typography>
                  <input
                    type="text"
                    id="subject"
                    className="mt-1 focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-background bg-background bg-clip-padding px-3 py-3 font-medium text-label-secundary transition-all focus:border-primary focus:outline-none focus:transition-shadow focus:bg-primary-100"
                    value={extractedData?.subject || ""}
                    onChange={(e) =>
                      setExtractedData({
                        ...extractedData,
                        subject: e.target.value,
                      })
                    }
                  />
                  <Button
                    disabled={!extractedData?.subject}
                    className="mt-5"
                    onClick={handleCreateAssignment}
                    color="primary"
                    type="button"
                  >
                    Crear Asignación
                  </Button>
                </div>
              )}
            </div>
            {!isResponseDocument && (
              <div className="w-full lg:w-1/2 bg-gray-100 flex flex-col h-full">
                <div className="flex-1 py-[30px] px-[70px] flex justify-center items-center">
                  <img
                    src={images[pageNumber - 1]}
                    alt={`Page ${pageNumber}`}
                    className="object-contain w-full h-full"
                  />
                </div>
                <div className="flex justify-center items-center">
                  <div className="fixed bottom-[15px] flex justify-center items-center">
                    <PaginationPDF
                      currentPage={pageNumber}
                      totalPages={numPages || 0}
                      onPrevious={goToPreviousPage}
                      onNext={goToNextPage}
                      onRefresh={refresh}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFViewer;
