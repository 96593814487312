interface IconDownloadProps {
  className?: string;
}

const IconDownloadSVG: React.FC<IconDownloadProps> = ({ className }) => {
  return (
    <svg
      width="120"
      height="103"
      viewBox="0 0 120 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_6_4415)">
        <path
          d="M99.231 87.5871C99.4194 87.5601 99.6003 21.4996 99.7936 21.4799C102.796 21.1816 105.799 22.0499 108.178 23.9049C110.558 25.7599 112.133 28.4594 112.577 31.444L119.871 80.5628C120.097 82.0887 120.021 83.6444 119.646 85.1409C119.272 86.6373 118.606 88.0454 117.687 89.2845C116.768 90.5237 115.614 91.5698 114.291 92.3629C112.968 93.1561 111.502 93.6809 109.976 93.9073L51.8074 102.544C48.7385 103 45.614 102.223 43.1158 100.383C40.6176 98.5433 38.9485 95.7901 38.4727 92.7243C38.4727 92.6997 38.4653 91.444 38.4616 91.4182"
          fill="#DEDEF9"
        />
        <path
          d="M88.9887 91.6533H30.1828C26.5788 91.6494 23.1234 90.216 20.5749 87.6677C18.0263 85.1194 16.5926 81.6641 16.5884 78.0601V46.9712C16.5884 46.4815 16.7829 46.0118 17.1292 45.6655C17.4755 45.3192 17.9452 45.1246 18.4349 45.1246C18.9247 45.1246 19.3944 45.3192 19.7407 45.6655C20.087 46.0118 20.2815 46.4815 20.2815 46.9712V78.0601C20.2848 80.685 21.329 83.2014 23.1852 85.0573C25.0414 86.9133 27.558 87.9572 30.1828 87.9602H88.9887C91.6134 87.9569 94.1296 86.9128 95.9855 85.0569C97.8415 83.201 98.8855 80.6847 98.8888 78.0601V28.3984C98.8859 25.7735 97.8419 23.257 95.986 21.4008C94.13 19.5446 91.6136 18.5004 88.9887 18.4971H46.8377C46.3479 18.4971 45.8782 18.3026 45.5319 17.9563C45.1856 17.61 44.9911 17.1403 44.9911 16.6505C44.9911 16.1608 45.1856 15.6911 45.5319 15.3448C45.8782 14.9985 46.3479 14.804 46.8377 14.804H88.9887C92.5928 14.8082 96.048 16.2419 98.5963 18.7904C101.145 21.339 102.578 24.7944 102.582 28.3984V78.0601C102.578 81.664 101.145 85.1192 98.5963 87.6676C96.0479 90.216 92.5927 91.6494 88.9887 91.6533Z"
          fill="#1F1F22"
        />
        <path
          d="M98.4493 78.8504C98.2068 78.8506 97.9667 78.8028 97.7428 78.7099C97.5188 78.6169 97.3155 78.4806 97.1444 78.3087L83.2606 64.4262C82.5257 63.7252 81.549 63.3341 80.5333 63.3341C79.5176 63.3341 78.5409 63.7252 77.8059 64.4262L69.0457 73.1851C68.6995 73.5313 68.2299 73.7258 67.7402 73.7258C67.2506 73.7258 66.781 73.5313 66.4347 73.1851L44.8827 51.632C44.1584 50.91 43.1774 50.5046 42.1547 50.5046C41.1321 50.5046 40.1511 50.91 39.4267 51.632L22.0271 69.0328C21.6808 69.379 21.2112 69.5735 20.7216 69.5735C20.232 69.5735 19.7624 69.379 19.4161 69.0328C19.0699 68.6865 18.8754 68.2169 18.8754 67.7273C18.8754 67.2376 19.0699 66.768 19.4161 66.4218L36.8157 49.0209C38.2329 47.6072 40.153 46.8132 42.1547 46.8132C44.1565 46.8132 46.0766 47.6072 47.4938 49.0209L67.7396 69.2679L75.1948 61.8152C75.8936 61.111 76.7253 60.5525 77.6416 60.1721C78.5579 59.7917 79.5405 59.597 80.5326 59.5993C81.5249 59.5969 82.5078 59.7916 83.4243 60.172C84.3408 60.5523 85.1727 61.1109 85.8717 61.8152L99.7554 75.6928C100.102 76.0391 100.296 76.5087 100.296 76.9983C100.296 77.4879 100.102 77.9575 99.7554 78.3038C99.5844 78.4764 99.381 78.6135 99.1568 78.7073C98.9327 78.8011 98.6923 78.8497 98.4493 78.8504Z"
          fill="#242424"
        />
        <path
          d="M80.1608 45.931C77.8522 45.9294 75.6386 45.0119 74.0057 43.3799C72.3728 41.748 71.4541 39.5348 71.4512 37.2263C71.4541 34.9183 72.3723 32.7057 74.0044 31.0739C75.6365 29.442 77.8492 28.5241 80.1571 28.5215C82.465 28.5241 84.6775 29.4421 86.3094 31.074C87.9413 32.7058 88.8593 34.9184 88.8619 37.2263C88.8593 39.5335 87.9418 41.7455 86.3107 43.3772C84.6797 45.009 82.468 45.9274 80.1608 45.931ZM80.1608 32.2147C78.8315 32.215 77.5567 32.7428 76.6162 33.6821C75.6756 34.6215 75.1463 35.8957 75.1443 37.225C75.1456 38.5541 75.6742 39.8284 76.614 40.7682C77.5538 41.708 78.8281 42.2365 80.1571 42.2378C81.4859 42.2365 82.7599 41.7081 83.6994 40.7685C84.639 39.829 85.1674 38.555 85.1687 37.2263C85.1674 35.8981 84.6395 34.6247 83.7007 33.6853C82.762 32.7458 81.4889 32.2169 80.1608 32.2147Z"
          fill="#1F1F22"
        />
        <path
          d="M20.2249 40.4565C31.3948 40.4565 40.4497 31.4015 40.4497 20.2317C40.4497 9.06176 31.3948 0.00677872 20.2249 0.00677872C9.05498 0.00677872 0 9.06176 0 20.2317C0 31.4015 9.05498 40.4565 20.2249 40.4565Z"
          fill="#DEDEF9"
        />
        <path
          d="M33.658 22.0795H6.79658C6.30684 22.0795 5.83716 21.8849 5.49086 21.5386C5.14456 21.1923 4.95001 20.7226 4.95001 20.2329C4.95001 19.7431 5.14456 19.2735 5.49086 18.9272C5.83716 18.5809 6.30684 18.3863 6.79658 18.3863H33.658C34.1478 18.3863 34.6175 18.5809 34.9637 18.9272C35.31 19.2735 35.5046 19.7431 35.5046 20.2329C35.5046 20.7226 35.31 21.1923 34.9637 21.5386C34.6175 21.8849 34.1478 22.0795 33.658 22.0795Z"
          fill="#5C5CE0"
        />
        <path
          d="M20.2249 35.5102C19.7351 35.5102 19.2654 35.3156 18.9191 34.9693C18.5728 34.623 18.3783 34.1533 18.3783 33.6636V6.80216C18.3783 6.31241 18.5728 5.84273 18.9191 5.49643C19.2654 5.15013 19.7351 4.95559 20.2249 4.95559C20.7146 4.95559 21.1843 5.15013 21.5306 5.49643C21.8769 5.84273 22.0714 6.31241 22.0714 6.80216V33.6636C22.0714 34.1533 21.8769 34.623 21.5306 34.9693C21.1843 35.3156 20.7146 35.5102 20.2249 35.5102Z"
          fill="#5C5CE0"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_4415">
          <rect width="120" height="102.68" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDownloadSVG;
