import React from "react";
import { IoMailOutline, IoList } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { NavbarDefault } from "../../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import Typography from "../../../../../../_emailApp/layout/components/Typography";
import { useNavigate } from "react-router-dom";
import { setTypeAssignment } from "../../../../../../features/assignments/assignmentSlice";

const SelectTypeAssignment: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNewAssignmentTask = () => {
    navigate("/dashboard/assignments/table-assignments/create-assignment", {
      state: { isResponseDocument: false },
    });
    dispatch(setTypeAssignment(true));
  };
  const handleNewAssignmentEmail = () => {
    navigate("/dashboard/assignments/table-assignments/preview-assignment", {
      state: { isResponseDocument: false },
    });
    dispatch(setTypeAssignment(false));
  };

  return (
    <>
      <div className="flex sm:ml-64 justify-center items-center min-h-screen flex-col">
        <NavbarDefault title={"Selecciona tipo de asignación"} />
        <div className="flex flex-row gap-10">
          <label
            htmlFor="task-upload"
            className="flex flex-col items-center justify-center p-3 border-2 border-solid border-bg-background rounded-lg cursor-pointer hover:bg-primary-100 hover:border-primary-500 transition duration-150 ease-in-out"
          >
            <div
              onClick={handleNewAssignmentTask}
              className="flex flex-col items-center justify-center space-y-4"
            >
              <IoList className="w-14 h-14 text-gray-400" />
              <Typography
                as="h3"
                textAlign="center"
                fontSize="text-sm"
                className="text-label-primary"
              >
                Asignación Tarea
              </Typography>
            </div>
          </label>
          <label
            htmlFor="email-upload"
            className="flex flex-col items-center justify-center p-3 border-2 border-solid border-bg-background rounded-lg cursor-pointer hover:bg-primary-100 hover:border-primary-500 transition duration-150 ease-in-out"
          >
            <div
              onClick={handleNewAssignmentEmail}
              className="flex flex-col items-center justify-center space-y-4"
            >
              <IoMailOutline className="w-14 h-14 text-gray-400" />
              <Typography
                as="h3"
                textAlign="center"
                fontSize="text-sm"
                className="text-label-primary"
              >
                Asignación Email
              </Typography>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default SelectTypeAssignment;
