import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../app/store';
import {
  logout as logoutAction,
  login as loginAction,
} from '../../../../features/auth/authSlice';

export const useAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, userRole } = useSelector(
    (state: RootState) => state.auth
  );

  const login = (role: string) => {
    dispatch(loginAction(role));
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  return {
    isAuthenticated,
    userRole,
    login,
    logout,
  };
};
