import React, { useState } from "react";
import PDFViewer from "./components/PDFViewer";
import PDFUploader from "./components/PDFUploader";
import { useLocation } from "react-router-dom";

const PreviewAssignments: React.FC = () => {
  const location = useLocation();

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const isResponseDocument = location?.state?.isResponseDocument as boolean;
  const taskId = location?.state?.taskId as string;

  const handleFileSelect = (file: File) => {
    const url = URL.createObjectURL(file);
    setPdfUrl(url);
  };

  return (
    <div className="p-4 sm:ml-64">
      <div className="flex">
        <div className="flex-1 justify-center items-center mx-3 sm:mx-10 mb-1">
          {pdfUrl ? (
            <PDFViewer
              pdfUrl={pdfUrl}
              isResponseDocument={isResponseDocument}
              taskId={taskId}
            />
          ) : (
            <PDFUploader
              onFileSelect={handleFileSelect}
              isResponseDocument={isResponseDocument}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewAssignments;
