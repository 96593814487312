import { fetchAPI } from "../../../../../api/fetchAPI";
import { BASE_API_URL } from "../../../../../constanst/constantURL";

export const fetchBoard = async (
  userId?: string,
  isUserRole: boolean = false
) => {
  try {
    const url =
      isUserRole && userId
        ? `${BASE_API_URL}/assignation/board`
        : userId
          ? `${BASE_API_URL}/assignation/board/user/${userId}`
          : `${BASE_API_URL}/assignation/board`;

    const response = await fetchAPI<any>({ url, method: "GET" });
    return response.data || [];
  } catch (error) {
    return [];
  }
};

export const saveProgressState = async (taskId: string, status: string) => {
  const response = await fetchAPI<any>({
    url: `${BASE_API_URL}/assignation/board/${taskId}`,
    method: "PUT",
    data: { assignationId: taskId, stage: status },
  });
  return response.data.data;
};

export const fetchAllUsers = async () => {
  const response = await fetchAPI<any>({
    url: `${BASE_API_URL}/user`,
    method: "GET",
  });
  return response.data;
};
