import React from "react";
import { Formik, Form } from "formik";
import SelectField from "../../../../../../_emailApp/layout/components/SelectField";
import Button from "../../../../../../_emailApp/layout/components/Button";
import { useRoles } from "../../../../../hooks/useRoles";

interface FilterModalProps {
  onSubmit: (values: {
    selectRoleId: string;
    selectDepartmentId: string;
  }) => void;
  departmentOptions: Array<{ value: string; label: string }>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: { selectRoleId: string; selectDepartmentId: string };
  onClearFilters: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  onSubmit,
  departmentOptions,
  setIsModalOpen,
  initialValues,
  onClearFilters,
}) => {
  const { getTranslatedRoleOptions } = useRoles();
  const roleOptions = getTranslatedRoleOptions();

  return (
    <div className="absolute md:ml-6 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          setIsModalOpen(false);
        }}
      >
        <Form className="flex flex-col pt-3 my-5 md:pt-4 mx-4 sm:mx-6">
          <SelectField
            label="Rol"
            placeholder="Selecciona una opción"
            name="selectRoleId"
            options={roleOptions}
          />
          <div className="mt-5">
            <SelectField
              label="Departamento"
              placeholder="Selecciona una opción"
              name="selectDepartmentId"
              options={departmentOptions}
            />
          </div>
          <div className="flex justify-end mt-4 gap-4">
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={onClearFilters}
            >
              Limpiar
            </Button>
            <Button type="submit" color="primary">
              Filtrar
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default FilterModal;
