import React from 'react';
import { IoChevronUp, IoChevronDown, IoRefresh } from 'react-icons/io5';

type PaginationPDFProps = {
  currentPage: number;
  totalPages: number;
  onPrevious: () => void;
  onNext: () => void;
  onRefresh: () => void;
};

const PaginationPDF: React.FC<PaginationPDFProps> = ({
  currentPage,
  totalPages,
  onPrevious,
  onNext,
  onRefresh,
}) => {
  return (
    <div className="flex justify-center">
      <div className="bg-gray-800 text-white p-2 rounded">
        <span className="mr-2">
          Página {currentPage} / {totalPages}
        </span>
        <button onClick={onPrevious} className="p-2 hover:bg-gray-700 rounded">
          <IoChevronUp />
        </button>
        <button onClick={onNext} className="p-2 hover:bg-gray-700 rounded">
          <IoChevronDown />
        </button>
        <button onClick={onRefresh} className="p-2 hover:bg-gray-700 rounded">
          <IoRefresh />
        </button>
      </div>
    </div>
  );
};

export default PaginationPDF;
