import Typography from '../Typography';

interface NavbarDefaultProps {
  title: string;
}

export const NavbarDefault = ({ title }: NavbarDefaultProps) => {
  return (
    <div className="flex flex-wrap justify-between items-center text-secundary pt-4 pb-4 px-4">
      <div className="w-full lg:w-auto mb-4 lg:mb-0">
        <Typography
          fontSize="text-xl"
          fontWeight="500"
          color="text-label-primary"
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};
