import React from 'react';
import Typography from './Typography';
import iconNoFound from '../../assets/png/IconNoFound.png';

const NoResultFound: React.FC = () => {
  return (
    <div className="flex justify-center items-center my-[120px]">
      <div className="text-center mx-auto">
        <div className="flex justify-center items-center mb-5">
          <img src={iconNoFound} alt="No Result Found" />
        </div>
        <Typography
          as="h2"
          textAlign="center"
          fontSize="text-sm"
          fontWeight="bold"
          className="text-gray-500"
          display="block"
        >
          No se han encontrado resultados
        </Typography>
        <Typography
          as="h3"
          textAlign="center"
          fontSize="text-sm"
          className="text-gray-500"
        >
          Por favor, inténtalo de nuevo con una consulta diferente.
        </Typography>
      </div>
    </div>
  );
};

export default NoResultFound;
