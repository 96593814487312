import React, { useEffect, useState } from "react";
import {
  IoArrowForwardOutline,
  IoDocumentTextOutline,
  IoWarningOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import { Formik, Form } from "formik";
import Modal from "../../../../../../../_emailApp/layout/components/Modal";
import Button from "../../../../../../../_emailApp/layout/components/Button";
import Typography from "../../../../../../../_emailApp/layout/components/Typography";
import { UserDetails } from "../../../../../../../features/user/types";
import { fetchAPI } from "../../../../../../api/fetchAPI";
import { setRefreshInvoice } from "../../../../../../../features/invoices/invoiceSlice";
import {
  STATUS_DESCRIPTIONS_INVOICE,
  STATUS_INVOICE,
  STATUS_PROCESSSTAGE,
} from "../../../../../../constanst/constants";
import { RootState } from "../../../../../../store";
import { formatDate } from "../../../../../../utils/transformDate";
import FilteredSelectSearch from "../../../../../../../_emailApp/layout/components/FilteredSelectSeach";
import LogoUser from "../../../../../../../_emailApp/layout/components/LogoUser";
import { User } from "../../../users/TableUser";
import IconDepartmentSVG from "../../../../../../../_emailApp/assets/svg/IconDepartmentSVG";
import SelectField from "../../../../../../../_emailApp/layout/components/SelectField";
import { setError } from "../../../../../../../features/errors/errorSlice";
import { getStatusLabelColor } from "../../../../../../utils/statusUtils";
import { BASE_API_URL } from "../../../../../../constanst/constantURL";

interface AssignmentStatusOption {
  value: string;
  label: string;
}

const assignmentStatusOptions: AssignmentStatusOption[] = [
  { value: "APPROVED", label: "Aprobado" },
  { value: "REJECTED", label: "Rechazado" },
  { value: "PAID", label: "Pagado" },
  { value: "CANCELED", label: "Cancelado" },
  { value: "ARCHIVED", label: "Archivado" },
];

export const ModalInvoice: React.FC<any> = ({
  isOpen,
  onClose,
  onSave,
  invoiceId,
  invoiceIssueDate,
  invoiceDueDate,
  invoiceNumber,
  assignedTo,
  invoiceUrl,
  vendorName,
  vendorNit,
  details,
  processStage,
  reviewer,
  status,
  trackingNumber,
  project,
}) => {
  const dispatch = useDispatch();

  const { userId } = useSelector(
    (state: RootState) => state.user.userDetails as UserDetails
  );

  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [buttonText, setButtonText] = useState<string>("Siguiente etapa");

  const getClassByState = (state: number): string => {
    if (state < 0) {
      return "bg-danger-200 text-danger";
    } else if (state > 5) {
      return "bg-info-200 text-info";
    } else if (state >= 1 && state <= 5) {
      return "bg-warning-200 text-warning";
    } else {
      return "bg-success-200 text-success";
    }
  };

  const formatDetails = (details: { item: string }[]) => {
    const combinedDetails = details?.map((detail) => detail?.item).join(", ");
    const formattedDetails =
      combinedDetails?.charAt(0)?.toUpperCase() +
      combinedDetails?.slice(1)?.toLowerCase();

    if (formattedDetails.length > 300) {
      return formattedDetails?.slice(0, 300) + "...";
    }

    return formattedDetails;
  };

  const daysDifference =
    invoiceIssueDate && invoiceDueDate
      ? moment(invoiceDueDate).diff(moment(invoiceIssueDate), "days") + 1
      : 0;

  const daysDifferenceDays = invoiceDueDate
    ? moment(invoiceDueDate).diff(moment(), "days") + 1
    : 0;

  const allUsersOptions = allUsers
    ?.filter((user: User) => user.userId !== userId)
    .map((user: User) => ({
      value: user.userId ?? "",
      label: `${user.name} ${user.lastName}`,
    }));

  const getAllUsers = async () => {
    try {
      const resp = await fetchAPI({
        url: `${BASE_API_URL}/user`,
        method: "GET",
      });

      if (
        Array.isArray(resp.data) &&
        resp.data.every((item) => typeof item.userId !== "undefined")
      ) {
        setAllUsers(resp.data);
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const getNextAssignmentProcessStage = (
    stage: (typeof STATUS_INVOICE)[number]
  ): string => {
    const currentIndex = STATUS_INVOICE.indexOf(stage);
    const nextStage = STATUS_INVOICE[currentIndex + 1] || "FINISHED";
    return nextStage;
  };

  const handleSave = async (values: any) => {
    try {
      const nextStage = getNextAssignmentProcessStage(
        processStage as (typeof STATUS_INVOICE)[number]
      );

      const getStatusOrNextStage = () => {
        return processStage === "IN_PAYMENT_PROCESS"
          ? values.status
          : nextStage;
      };

      const requestData: any = {
        reviewer: reviewer?.userId || values.reviewerSelect,
        status: getStatusOrNextStage(),
        processStage: nextStage,
      };

      if (processStage === "IN_PAYMENT_PROCESS") {
        requestData.invoiceType = "Factura electronica";
      }

      if (processStage === "UNDER_REVIEW") {
        requestData.reviewer = reviewer;
      }

      const response = await fetchAPI<any>({
        url: `${BASE_API_URL}/expense/${invoiceId}`,
        method: "PUT",
        data: requestData,
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "El estado del progreso fue actualizado con éxito.",
          })
        );
      }

      dispatch(setRefreshInvoice());
      if (onSave) {
        onSave();
      }
      onClose();
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage: "Error inesperado. Inténtalo de nuevo.",
        })
      );
    }
  };

  const onLookUpDocument = () => {
    window.open(invoiceUrl || "", "_blank");
  };

  useEffect(() => {
    getAllUsers();
  }, [processStage]);

  useEffect(() => {
    if (processStage) {
      const nextStageDescription = getNextAssignmentProcessStage(
        processStage as (typeof STATUS_INVOICE)[number]
      );
      setButtonText(
        STATUS_DESCRIPTIONS_INVOICE[
          nextStageDescription as keyof typeof STATUS_DESCRIPTIONS_INVOICE
        ]
      );
    }
  }, [processStage]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          reviewerSelect: "",
          reviewed: false,
          status: "",
        }}
        onSubmit={handleSave}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="flex">
              <div className="w-3/4 sm:pr-8">
                <div className="flex">
                  <IconDepartmentSVG className="w-5 mr-2" />
                  <Typography
                    as="h2"
                    fontWeight="bold"
                    display="inline"
                    fontSize="text-lg"
                  >
                    {trackingNumber?.toUpperCase()}
                  </Typography>
                  <div className="flex ml-5">
                    <div
                      className={clsx(
                        "rounded-full px-[20px] text-sm",
                        getClassByState(daysDifferenceDays),
                        "flex items-center h-full"
                      )}
                    >
                      {`${daysDifferenceDays} ${
                        daysDifferenceDays === 1 ? "día" : "días"
                      }`}
                    </div>
                  </div>
                </div>
                <div className="my-3">
                  <button
                    onClick={onLookUpDocument}
                    className="mr-3 border border-1 p-2 bg-background rounded-lg hover:bg-primary-500 hover:text-white transition duration-200"
                    type="button"
                  >
                    <IoDocumentTextOutline size={20} />
                  </button>
                </div>
                {processStage === "FINISHED" && (
                  <div className="flex py-[5px]">
                    <div
                      className={`border border-${getStatusLabelColor(status)} rounded-lg px-2 py-1`}
                    >
                      <Typography
                        fontSize="text-xs"
                        fontWeight="lighter"
                        className={`text-${getStatusLabelColor(status)}`}
                      >
                        {STATUS_PROCESSSTAGE[status] || status}
                      </Typography>
                    </div>
                  </div>
                )}
                <Typography
                  as="h3"
                  fontWeight="bold"
                  textAlign="left"
                  fontSize="text-lg"
                  className="mt-2 mr-1"
                >
                  Proyecto: {project}
                </Typography>
                <Typography
                  as="h3"
                  fontWeight="bold"
                  fontSize="text-lg"
                  className="mt-2"
                  color="text-primary"
                >
                  Proveedor: {vendorName}
                </Typography>
                <Typography as="h3" fontWeight="bold" fontSize="text-base">
                  No: {invoiceNumber}
                </Typography>
                <div className="mt-[20px]">
                  <Typography as="h3" fontWeight="normal" fontSize="text-base">
                    {formatDetails(details)}
                  </Typography>
                </div>
                <div className="flex mt-4">
                  <div className="flex flex-1 flex-col">
                    <Typography fontWeight="bolder" as="h3" fontSize="text-sm">
                      Fecha de Emisión:
                    </Typography>
                    <Typography as="h3" fontWeight="normal" fontSize="text-sm">
                      {formatDate(invoiceIssueDate || "")}
                    </Typography>
                  </div>
                  <div className="flex flex-1 flex-col">
                    <Typography fontWeight="bolder" as="h3" fontSize="text-sm">
                      Fecha de Vencimiento:
                    </Typography>
                    <Typography as="h3" fontWeight="normal" fontSize="text-sm">
                      {formatDate(invoiceDueDate || "")}
                    </Typography>
                  </div>
                </div>
                {processStage === "IN_PAYMENT_PROCESS" && (
                  <div className="w-[230px] mt-4">
                    <SelectField
                      name="status"
                      label="Estado de Asignación"
                      options={assignmentStatusOptions}
                      placeholder="Seleccione un estado"
                      labelSize="text-sm"
                    />
                  </div>
                )}
                {!values.status && processStage === "IN_PAYMENT_PROCESS" && (
                  <div className="flex mt-[10px] items-center">
                    <IoWarningOutline color="#F64E60" />
                    <Typography
                      fontWeight="600"
                      fontSize="text-sm"
                      className="ml-1"
                    >
                      Es necesario seleccionar un estado para continuar con esta
                      tarea
                    </Typography>
                  </div>
                )}
                <div className="flex w-[230px] mt-4">
                  {processStage === "RECEIVED" && (
                    <div className="flex flex-1 flex-col">
                      <Typography
                        fontWeight="bolder"
                        as="h3"
                        fontSize="text-sm"
                        className="pb-1"
                      >
                        Asignar a:
                      </Typography>
                      <FilteredSelectSearch
                        name="reviewerSelect"
                        label=""
                        options={allUsersOptions}
                        placeholder="Asignar a"
                        className="my-custom-class"
                        value={values.reviewerSelect}
                        onChange={(value: any) =>
                          setFieldValue("reviewerSelect", value)
                        }
                      />
                    </div>
                  )}
                </div>
                {!values.reviewerSelect && processStage === "RECEIVED" && (
                  <div className="flex mt-[10px] items-center">
                    <IoWarningOutline color="#F64E60" />
                    <Typography
                      fontWeight="600"
                      fontSize="text-sm"
                      className="ml-1"
                    >
                      Es necesario asignar un responsable para continuar con
                      esta tarea
                    </Typography>
                  </div>
                )}
              </div>
              <div className="flex w-1/4">
                <div className="flex flex-col justify-center items-start">
                  <div className="flex items-center mb-2">
                    <IoArrowForwardOutline />
                    <Typography
                      fontWeight="bolder"
                      as="h3"
                      fontSize="text-sm"
                      className="pl-1"
                    >
                      Próximo paso
                    </Typography>
                  </div>
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    className="mt-4 mb-5"
                    width="150px"
                  >
                    {buttonText}
                  </Button>
                  <div className="flex mb-4">
                    {assignedTo ? (
                      <>
                        <LogoUser
                          name={assignedTo?.name || ""}
                          lastName={assignedTo?.lastName || ""}
                          size={40}
                        />
                        <div className="ml-3">
                          <Typography
                            as="h3"
                            fontSize="text-sm"
                            fontWeight="bolder"
                          >
                            {assignedTo?.name}
                          </Typography>
                          <Typography as="h3" fontSize="text-sm">
                            {assignedTo?.lastName}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <Typography as="h3" fontSize="text-sm">
                        Asignado a: {assignedTo}
                      </Typography>
                    )}
                  </div>
                  <div className="mb-4">
                    <Typography
                      fontWeight="bolder"
                      as="h3"
                      fontSize="text-sm"
                      className="pb-1"
                    >
                      Estimación
                    </Typography>
                    <Typography as="h3" fontWeight="normal" fontSize="text-sm">
                      {`${daysDifference} ${
                        daysDifference === 1 || daysDifference === -1
                          ? "día"
                          : "días"
                      }`}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
